import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { CoQuanQuanLy } from 'src/app/shared/CoQuanQuanLy.model';
import { CoQuanQuanLyService } from 'src/app/shared/CoQuanQuanLy.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';

@Component({
  selector: 'app-co-quan-quan-ly-detail',
  templateUrl: './co-quan-quan-ly-detail.component.html',
  styleUrls: ['./co-quan-quan-ly-detail.component.css']
})
export class CoQuanQuanLyDetailComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;
  constructor(  
    public CoQuanQuanLyService: CoQuanQuanLyService,    
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public NotificationService: NotificationService,
    public dialogRef: MatDialogRef<CoQuanQuanLyDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ID = data["ID"] as number;
    this.CoQuanQuanLyService.formData.DanhMucTinhThanhID = environment.HoChiMinhCityID;
    this.DanhMucQuanHuyenGetAllToListAsync();
  }
  ngOnInit(): void {        
  }  
  DanhMucQuanHuyenGetAllToListAsync() {
    this.isShowLoading = true;    
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync(this.CoQuanQuanLyService.formData.DanhMucTinhThanhID).subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = res as DanhMucQuanHuyen[];
        this.DanhMucQuanHuyenService.list = this.DanhMucQuanHuyenService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));        
        this.DanhMucXaPhuongGetAllToListAsync();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetAllToListAsync() {    
    this.DanhMucXaPhuongService.GetByParentIDToListAsync(this.CoQuanQuanLyService.formData.DanhMucQuanHuyenID).subscribe(
      res => {
        this.DanhMucXaPhuongService.list = res as DanhMucXaPhuong[];
        this.DanhMucXaPhuongService.list = this.DanhMucXaPhuongService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));        
      },
      err => {        
      }
    );
  }
  onClose() {
    this.dialogRef.close();
  }
  onSubmit(form: NgForm) {
    console.log(this.CoQuanQuanLyService.formData);
    this.CoQuanQuanLyService.SaveAsync(this.CoQuanQuanLyService.formData).subscribe(
      res => {
        this.CoQuanQuanLyService.formData = res as CoQuanQuanLy;
        this.onClose();
        this.NotificationService.success(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }  
}
