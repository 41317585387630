<div class="row">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">
                        Chuyển Bài viết <span
                            *ngIf="CoQuanQuanLyBaiVietService.list">({{CoQuanQuanLyBaiVietService.list.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item">
                            <a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="#" style="color: #ffffff;">Cơ quan quản lý</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">
                            Chuyển Bài viết
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div class="card-content">
                        <div class="col s12 m12 l2">
                            <mat-select
                                style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                name="danhMucBaiVietID" class="browser-default" [(ngModel)]="this.danhMucBaiVietID">
                                <mat-option *ngFor="let item of DanhMucBaiVietService.list" [value]="item.ID">
                                    {{item.Name}}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col s12 m12 l3">
                            <input matInput [(ngModel)]="searchString" placeholder="Tìm ..."
                                class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                (keyup.enter)="onSearch()">
                        </div>
                        <div class="col s12 m12 l1">
                            <a title="Tìm" (click)="onSearch()"
                                class="btn-floating waves-effect waves-light green darken-1">
                                <i class="material-icons">search</i>
                            </a>
                        </div>
                        <div class="col s12 m12 l2">
                            <mat-select
                                style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                name="danhMucBaiVietID001" class="browser-default"
                                [(ngModel)]="this.danhMucBaiVietID001">
                                <mat-option *ngFor="let item of DanhMucBaiVietService.list" [value]="item.ID">
                                    {{item.Name}}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col s12 m12 l2">
                            <label>
                                <input type="checkbox" class="filled-in" name="isSelectAll" [(ngModel)]="isSelectAll" (change)="onSelectAllChange()" />
                                <span>Chọn hết</span>
                            </label>
                        </div>
                        <div class="col s12 m12 l1">
                            <a title="Lưu thay đổi" (click)="onChuyen()"
                                class="btn-floating waves-effect waves-light cyan">
                                <i class="material-icons">save</i>
                            </a>
                        </div>
                        <div class="KhungOverflow">
                            <mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="Code">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Hình ảnh
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <a target="_blank" href="{{element.Code}}" *ngIf="element.Code"
                                            title="{{element.Code}}">
                                            <img class="responsive-img" width="50%" title="{{element.Code}}"
                                                alt="{{element.Code}}" [src]="element.Code"></a>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Name}}"
                                            (click)="onAdd(element.ID)"><b>{{element.Name}}</b>
                                        </a>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Select">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Chọn
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <label>
                                            <input type="checkbox" class="filled-in" 
                                                name="Select{{element.ID}}" [(ngModel)]="element.Select" />
                                            <span>.</span>
                                        </label>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="loading">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Đang tải dữ liệu...
                                    </mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="noData">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Chưa có dữ liệu.
                                    </mat-footer-cell>
                                </ng-container>
                                <mat-header-row
                                    *matHeaderRowDef="CoQuanQuanLyBaiVietService.displayColumnsChuyenBaiViet"></mat-header-row>
                                <mat-row
                                    *matRowDef="let row; columns: CoQuanQuanLyBaiVietService.displayColumnsChuyenBaiViet;"></mat-row>
                                <mat-footer-row *matFooterRowDef="['loading']"
                                    [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                                <mat-footer-row *matFooterRowDef="['noData']"
                                    [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                                </mat-footer-row>
                            </mat-table>
                            <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>