<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Cơ quan quản lý</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Tiêu đề</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Tiêu đề" name="Name" [(ngModel)]="CoQuanQuanLyService.formData.Name" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="DienThoai">Điện thoại</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Điện thoại" name="DienThoai" [(ngModel)]="CoQuanQuanLyService.formData.DienThoai"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Email">Email</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Email" name="Email" [(ngModel)]="CoQuanQuanLyService.formData.Email" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Website">Website</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Website" name="Website" [(ngModel)]="CoQuanQuanLyService.formData.Website"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="DiaChi">Địa chỉ</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <div class="input-field">
                    <input placeholder="Số nhà/ tên đường" name="DiaChi"
                        [(ngModel)]="CoQuanQuanLyService.formData.DiaChi" type="text">
                </div>
            </div>
        </div>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">Quận Huyện</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <mat-select
                    style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                    name="DanhMucQuanHuyenID" class="browser-default"
                    [(ngModel)]="CoQuanQuanLyService.formData.DanhMucQuanHuyenID"
                    (selectionChange)="DanhMucXaPhuongGetAllToListAsync()">
                    <mat-option *ngFor="let item of DanhMucQuanHuyenService.list" [value]="item.ID">
                        {{item.Name}}
                    </mat-option>
                </mat-select>
            </div>

        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">Xã Phường</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <mat-select
                    style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                    name="DanhMucXaPhuongID" class="browser-default"
                    [(ngModel)]="CoQuanQuanLyService.formData.DanhMucXaPhuongID">
                    <mat-option *ngFor="let item of DanhMucXaPhuongService.list" [value]="item.ID">
                        {{item.Name}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">Ghi chú</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Ghi chú" name="Note" [(ngModel)]="CoQuanQuanLyService.formData.Note" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SortOrder">Sắp xếp</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <div class="input-field">
                    <input placeholder="0" name="SortOrder" [(ngModel)]="CoQuanQuanLyService.formData.SortOrder"
                        type="text">
                </div>
            </div>
        </div>
        <div class="col s12 m12 l4">
            <div class="input-field">
                <label for="Active">Trạng thái</label>
            </div>
        </div>
        <div class="col s12 m12 l8">
            <div class="input-field">
                <p>
                    <label>
                        <input type="checkbox" class="filled-in" name="Active"
                            [(ngModel)]="CoQuanQuanLyService.formData.Active" />
                        <span>Phê duyệt / Chưa phê duyệt</span>
                    </label>
                </p>
            </div>
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>