import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class UploadService {
    aPIURL: string = environment.APIURL;
    controller: string = "Upload";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
    }

    PostCoQuanQuanLyBaiVietListByExcelFileAsync(fileToUpload: File,danhMucBaiVietID:string) {
        let url = this.aPIURL + this.controller + '/PostCoQuanQuanLyBaiVietListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        formUpload.append('danhMucBaiVietID',danhMucBaiVietID);
        return this.httpClient.post(url, formUpload);
    } 
    PostCoSoLuuTru_NhaHangListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTru_NhaHangListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }  
    PostCoSoLuuTru_DonViLuHanhListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTru_DonViLuHanhListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }  
    PostCoSoLuuTru_DiemDuLichListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTru_DiemDiLichListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }  
    PostCoSoLuuTru_KhachSanListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTru_KhachSanListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }  
    PostCoSoLuuTruListByExcelFileAsync(fileToUpload: File,parentID:string) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTruListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        formUpload.append('parentID',parentID);
        return this.httpClient.post(url, formUpload);
    }   
    PostDanhMucTinhThanhListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostDanhMucTinhThanhListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostDanhMucQuanHuyenListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostDanhMucQuanHuyenListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostDanhMucXaPhuongListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostDanhMucXaPhuongListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostDanhMucQuocGiaListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostDanhMucQuocGiaListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostDanhMucCoSoLuuTruListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostDanhMucCoSoLuuTruListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }v
    PostCoSoLuuTruDichVuListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTruDichVuListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostCoSoLuuTruTourListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTruTourListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostCoSoLuuTruPhongListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTruPhongListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostCoSoLuuTruMonAnListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTruMonAnListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostCoSoLuuTruHoatDongListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTruHoatDongListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostCoSoLuuTruSuKienListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTruSuKienListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostCoSoLuuTruSocialListByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostCoSoLuuTruSocialListByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
}

