import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruSuKien } from 'src/app/shared/CoSoLuuTruSuKien.model';
import { CoSoLuuTruSuKienService } from 'src/app/shared/CoSoLuuTruSuKien.service';
import { CoSoLuuTruSuKienDetailComponent } from 'src/app/co-so-luu-tru-su-kien/co-so-luu-tru-su-kien-detail/co-so-luu-tru-su-kien-detail.component';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';

@Component({
  selector: 'app-su-kien',
  templateUrl: './su-kien.component.html',
  styleUrls: ['./su-kien.component.css']
})
export class SuKienComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  queryString: string = environment.InitializationString;
  ID: number = environment.InitializationNumber;
  parentID: number = environment.InitializationNumber;
  constructor(
    public router: Router,
    public CoSoLuuTruSuKienService: CoSoLuuTruSuKienService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public NotificationService: NotificationService,
    public dialog: MatDialog
  ) {
    this.CoSoLuuTruGetAllToListAsync();
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        if (this.queryString.split("/").length == 3) {
          this.ID = Number(this.queryString.split("/")[2]);
        }
        this.onSearch();
      }
    });
  }

  ngOnInit(): void {

  }

  onFilterCoSoLuuTru(searchString: string) {
    if (searchString.length > 0) {
      searchString = searchString.toLocaleLowerCase();
      this.CoSoLuuTruService.listSearch = this.CoSoLuuTruService.list.filter((item: any) =>
        item.TenDangKyKinhDoanh.toLocaleLowerCase().indexOf(searchString) !== -1);
    }
    else {
      this.CoSoLuuTruService.listSearch = this.CoSoLuuTruService.list;
    }
  }
  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = (res as CoSoLuuTru[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.CoSoLuuTruService.listSearch = this.CoSoLuuTruService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetToListAsync() {    
    this.isShowLoading = true;
    if (this.ID > 0) {
      this.CoSoLuuTruSuKienService.GetByIDToListAsync(this.ID).subscribe(
        res => {
          this.CoSoLuuTruSuKienService.list = res as CoSoLuuTruSuKien[];          
          this.dataSource = new MatTableDataSource(this.CoSoLuuTruSuKienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          if (this.CoSoLuuTruSuKienService.list) {
            if (this.CoSoLuuTruSuKienService.list.length > 0) {
              this.parentID = this.CoSoLuuTruSuKienService.list[0].ParentID;
            }
          }
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    else {
      this.CoSoLuuTruSuKienService.GetByParentIDToListAsync(this.parentID).subscribe(
        res => {
          this.CoSoLuuTruSuKienService.list = res as CoSoLuuTruSuKien[];
          this.dataSource = new MatTableDataSource(this.CoSoLuuTruSuKienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }    
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToListAsync();
    }
  }
  SuKienAdd(ID: any) {
    this.CoSoLuuTruSuKienService.GetByIDAsync(ID).subscribe(
      res => {
        this.CoSoLuuTruSuKienService.formData = res as CoSoLuuTruSuKien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoSoLuuTruSuKienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.onSearch();
        });
      },
      err => {
      }
    );
  }
  onDelete(element: CoSoLuuTruSuKien) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruSuKienService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
}
