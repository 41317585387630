import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { CoQuanQuanLyBaiViet } from 'src/app/shared/CoQuanQuanLyBaiViet.model';
import { CoQuanQuanLyBaiVietService } from 'src/app/shared/CoQuanQuanLyBaiViet.service';
import { DanhMucNgonNgu } from 'src/app/shared/DanhMucNgonNgu.model';
import { DanhMucNgonNguService } from 'src/app/shared/DanhMucNgonNgu.service';
import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { DanhMucBaiVietService } from 'src/app/shared/DanhMucBaiViet.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';

import { DanhMucDanhHieu } from 'src/app/shared/DanhMucDanhHieu.model';
import { DanhMucDanhHieuService } from 'src/app/shared/DanhMucDanhHieu.service';
import { DanhHieu } from 'src/app/shared/DanhHieu.model';
import { DanhHieuService } from 'src/app/shared/DanhHieu.service';

@Component({
  selector: 'app-co-quan-quan-ly-bai-viet-detail',
  templateUrl: './co-quan-quan-ly-bai-viet-detail.component.html',
  styleUrls: ['./co-quan-quan-ly-bai-viet-detail.component.css']
})
export class CoQuanQuanLyBaiVietDetailComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;


  constructor(
    public DanhHieuService: DanhHieuService,
    public DanhMucDanhHieuService: DanhMucDanhHieuService,
    public CoQuanQuanLyBaiVietService: CoQuanQuanLyBaiVietService,
    public DanhMucNgonNguService: DanhMucNgonNguService,
    public DanhMucBaiVietService: DanhMucBaiVietService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public NotificationService: NotificationService,
    public dialogRef: MatDialogRef<CoQuanQuanLyBaiVietDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ID = data["ID"] as number;
  }
  ngOnInit(): void {
    this.DanhMucDanhHieuGetAllToListAsync();
    this.DanhMucNgonNguGetAllToListAsync();
    this.DanhMucBaiVietGetAllToListAsync();
    this.DanhMucQuanHuyenGetAllToListAsync();
  }

  DanhMucDanhHieuGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucDanhHieuService.GetByCoQuanQuanLyBaiVietIDAndDanhHieuCodeToListAsync(this.CoQuanQuanLyBaiVietService.formData.ID,this.CoQuanQuanLyBaiVietService.controller).subscribe(
      res => {
        this.DanhMucDanhHieuService.list = (res as DanhMucDanhHieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)).filter(x=> x.IsHasStar !=true) ;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  CoQuanQuanLyBaiVietGetBaiVietTiengAnh(MapNgonNgu?: string, ID?:number) {
    this.isShowLoading = true;
    this.CoQuanQuanLyBaiVietService.GetByMapNgonNguAsync(MapNgonNgu,environment.DanhMucNgonNguTiengAnhID).subscribe(
      res => {
        var dataTiengAnh = res as CoQuanQuanLyBaiViet;
        this.onClose(dataTiengAnh);
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }


  DanhMucNgonNguGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucNgonNguService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucNgonNguService.list = (res as DanhMucNgonNgu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.CoQuanQuanLyBaiVietService.formData.DanhMucNgonNguName= this.DanhMucNgonNguService.list.filter(x=>x.ID ==this.CoQuanQuanLyBaiVietService.formData.DanhMucNgonNguID)[0].Name;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucBaiVietGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucBaiVietService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucBaiVietService.list = (res as DanhMucBaiViet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync(environment.HoChiMinhCityID).subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync(this.CoQuanQuanLyBaiVietService.formData.DanhMucQuanHuyenID).subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onClose(coQuanQuanLyBaiViet?: CoQuanQuanLyBaiViet) {
    this.dialogRef.close(coQuanQuanLyBaiViet);
  }
  fileToUpload: any;
  fileToUpload0: File = null;
  changeImage(files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.CoQuanQuanLyBaiVietService.formData.Code = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
  onSubmit(form: NgForm) {
    this.CoQuanQuanLyBaiVietService.SaveAndUploadFileAsync(this.CoQuanQuanLyBaiVietService.formData, this.fileToUpload).subscribe(
      res => {
        this.CoQuanQuanLyBaiVietService.formData = res as CoQuanQuanLyBaiViet;

        //this.onClose(this.CoQuanQuanLyBaiVietService.formData);
        this.CoQuanQuanLyBaiVietGetBaiVietTiengAnh(this.CoQuanQuanLyBaiVietService.formData.MapNgonNgu, this.CoQuanQuanLyBaiVietService.formData.ID);
        this.NotificationService.success(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }


  onSubmitDanhHieu(element: DanhMucDanhHieu) {
    
   
    this.DanhHieuService.formData.Active = (element.Active ==false)?true:false;
    this.DanhHieuService.formData.ParentID = this.CoQuanQuanLyBaiVietService.formData.ID;
    this.DanhHieuService.formData.Code = this.CoQuanQuanLyBaiVietService.controller;
    this.DanhHieuService.formData.DanhMucDanhHieuID =element.ID;

    this.DanhHieuService.SaveAsync(this.DanhHieuService.formData).subscribe(
      res => {
        this.DanhMucDanhHieuGetAllToListAsync();
        this.NotificationService.success(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
}