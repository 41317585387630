import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoSoLuuTruService extends BaseService {
    displayColumns: string[] = ['Code', 'TenDangKyKinhDoanh', 'DienThoai', 'DiaChi', 'Active', 'Save'];
    displayColumnsThanhVienCoSoLuuTru: string[] = ['LastUpdatedMembershipID', 'Code', 'TenDangKyKinhDoanh', 'DienThoai', 'DiaChi', 'Active', 'Save'];
    listSearch: CoSoLuuTru[] | undefined;
    list: CoSoLuuTru[] | undefined;
    formData!: CoSoLuuTru;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoSoLuuTru";
    }
    SaveAndUploadFileAsync(formData: CoSoLuuTru, fileToUpload: FileList) {
        var thanhVienID = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienID) {
            formData.LastUpdatedMembershipID = Number(thanhVienID);
        }
        let url = this.aPIURL + this.controller + '/SaveAndUploadFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(formData));
        if (fileToUpload) {
            if (fileToUpload.length > 0) {
                for (var i = 0; i < fileToUpload.length; i++) {
                    formUpload.append('file[]', fileToUpload[i]);
                }
            }
        }
        return this.httpClient.post(url, formUpload);
    }
    GetByIDStringAsync(ID: string) {
        let url = this.aPIURL + this.controller + '/GetByIDStringAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('ID', JSON.stringify(ID));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_QuanHuyenID_XaPhuongIDAsync(parentID: number, quanHuyenID: number, xaPhuongID: number) {
        let url = this.aPIURL + this.controller + '/GetByParentID_QuanHuyenID_XaPhuongIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('parentID', JSON.stringify(parentID));
        formUpload.append('quanHuyenID', JSON.stringify(quanHuyenID));
        formUpload.append('xaPhuongID', JSON.stringify(xaPhuongID));
        return this.httpClient.post(url, formUpload);
    }
    GetByThanhVienIDToListAsync(thanhVienID: number) {
        let url = this.aPIURL + this.controller + '/GetByThanhVienIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('thanhVienID', JSON.stringify(thanhVienID));
        return this.httpClient.post(url, formUpload);
    }

    GetByMapNgonNguAsync(mapNgonNgu: string,danhMucNgonNguID: number){
        let url = this.aPIURL + this.controller + '/GetByMapNgonNguAsync';
        const formUpload: FormData = new FormData();       
        formUpload.append('mapNgonNgu', JSON.stringify(mapNgonNgu));    
        formUpload.append('danhMucNgonNguID', JSON.stringify(danhMucNgonNguID));              
        return this.httpClient.post(url, formUpload);
    }
}
