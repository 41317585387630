import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruPhong } from 'src/app/shared/CoSoLuuTruPhong.model';
import { CoSoLuuTruPhongService } from 'src/app/shared/CoSoLuuTruPhong.service';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';
import { CoSoLuuTruPhongComponent } from './co-so-luu-tru-phong/co-so-luu-tru-phong.component';

@Component({
  selector: 'app-phong',
  templateUrl: './phong.component.html',
  styleUrls: ['./phong.component.css']
})
export class PhongComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  queryString: string = environment.InitializationString;
  ID: number = environment.InitializationNumber;
  parentID: number = environment.InitializationNumber;

  constructor(
    public router: Router,
    public CoSoLuuTruPhongService: CoSoLuuTruPhongService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public NotificationService: NotificationService,
    public dialog: MatDialog,
  ) {
    this.CoSoLuuTruGetAllToListAsync();
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        if (this.queryString.split("/").length == 3) {
          this.ID = Number(this.queryString.split("/")[2]);
        }
        this.onSearch();
      }
    });
  }

  ngOnInit(): void {
  }
  onFilterCoSoLuuTru(searchString: string) {
    if (searchString.length > 0) {
      searchString = searchString.toLocaleLowerCase();
      this.CoSoLuuTruService.listSearch = this.CoSoLuuTruService.list.filter((item: any) =>
        item.TenDangKyKinhDoanh.toLocaleLowerCase().indexOf(searchString) !== -1);
    }
    else {
      this.CoSoLuuTruService.listSearch = this.CoSoLuuTruService.list;
    }
  }
  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = (res as CoSoLuuTru[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.CoSoLuuTruService.listSearch = this.CoSoLuuTruService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }


  GetToListAsync() {
    this.isShowLoading = true;
    if (this.ID > 0) {
      this.CoSoLuuTruPhongService.GetByIDToListAsync(this.ID).subscribe(
        res => {
          this.CoSoLuuTruPhongService.list = res as CoSoLuuTruPhong[];
          this.dataSource = new MatTableDataSource(this.CoSoLuuTruPhongService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          if (this.CoSoLuuTruPhongService.list) {
            if (this.CoSoLuuTruPhongService.list.length > 0) {
              this.parentID = this.CoSoLuuTruPhongService.list[0].ParentID;
            }
          }
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    else {      
      this.CoSoLuuTruPhongService.GetByParentIDToListAsync(this.parentID).subscribe(
        res => {
          this.CoSoLuuTruPhongService.list = res as CoSoLuuTruPhong[];
          this.dataSource = new MatTableDataSource(this.CoSoLuuTruPhongService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToListAsync();
    }
  }
  onDelete(element: CoSoLuuTruPhong) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruPhongService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  PhongAdd(ID: any) {
    this.CoSoLuuTruPhongService.GetByIDAsync(ID).subscribe(
      res => {
        this.CoSoLuuTruPhongService.formData = res as CoSoLuuTruPhong;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoSoLuuTruPhongComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.onSearch();
        });
      },
      err => {
      }
    );
  }
}