<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Bài viết <span class="text-success">{{this.CoQuanQuanLyBaiVietService.formData.DanhMucNgonNguName}}</span> </b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <input name="ID" [(ngModel)]="CoQuanQuanLyBaiVietService.formData.ID" type="hidden">
    <div class="col s12 m12 l5">
        <!--
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="DanhMucNgonNguID">Ngôn ngữ</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="DanhMucNgonNguID" class="browser-default"
                [(ngModel)]="CoQuanQuanLyBaiVietService.formData.DanhMucNgonNguID">
                <mat-option *ngFor="let item of DanhMucNgonNguService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        -->
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="DanhMucBaiVietID">Chủ đề</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="DanhMucBaiVietID" class="browser-default"
                [(ngModel)]="CoQuanQuanLyBaiVietService.formData.DanhMucBaiVietID">
                <mat-option *ngFor="let item of DanhMucBaiVietService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="DanhMucQuanHuyenID">Quận huyện</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="DanhMucQuanHuyenID" class="browser-default"  (selectionChange)="DanhMucXaPhuongGetAllToListAsync()"
                [(ngModel)]="CoQuanQuanLyBaiVietService.formData.DanhMucQuanHuyenID">
                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="DanhMucXaPhuongID">Xã phường</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="DanhMucXaPhuongID" class="browser-default"
                [(ngModel)]="CoQuanQuanLyBaiVietService.formData.DanhMucXaPhuongID">
                <mat-option *ngFor="let item of DanhMucXaPhuongService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Tiêu đề</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Tiêu đề" name="Name" [(ngModel)]="CoQuanQuanLyBaiVietService.formData.Name"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Description">Mô tả</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Mô tả" name="Description"
                    [(ngModel)]="CoQuanQuanLyBaiVietService.formData.Description" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="KinhDo">Kinh độ</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Kinh độ" name="KinhDo"
                    [(ngModel)]="CoQuanQuanLyBaiVietService.formData.KinhDo" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="ViDo">Vĩ độ</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Vĩ độ" name="ViDo"
                    [(ngModel)]="CoQuanQuanLyBaiVietService.formData.ViDo" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SortOrder">Sắp xếp</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input style="text-align: right;" placeholder="0" name="SortOrder"
                    [(ngModel)]="CoQuanQuanLyBaiVietService.formData.SortOrder" type="number">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">Tình trạng</label>
            </div>
        </div>
        <div class="col s8 m8 l8 mt-2 mb-3">
            <div class="input-field">
                <p>
                    <label>
                        <input type="checkbox" class="filled-in" name="Active"
                            [(ngModel)]="CoQuanQuanLyBaiVietService.formData.Active" />
                        <span>Phê duyệt / Chưa phê duyệt</span>
                    </label>
                </p>
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Code">Hình đại diện</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input type="file" (change)="changeImage($event.target.files)">
            </div>
            <div class="input-field">
                <img class="responsive-img" [src]="CoQuanQuanLyBaiVietService.formData.Code">
            </div>
        </div>
    </div>
    <div class="col s12 m12 l7">
        <ck-editor [config]="{'height': 600,versionCheck: false}" name="Note" [(ngModel)]="CoQuanQuanLyBaiVietService.formData.Note"
            skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
            
            <div style="height: 50px;"> </div>
            <div class="col s12 m12 l12">
                <div class="input-field">
                    <label for="Code">Danh hiệu</label>
                </div>
            </div>
            <div style="height: 50px;"> </div>
            <div class="col s12 m12 l12">
                <div *ngFor="let element of DanhMucDanhHieuService.list;" style="float: left; padding:10px;margin: 10px;width: 100px;box-shadow: 1px 1px 9px rebeccapurple;">
                    <img src="{{element.Code}}" style="float: left;width: 50px;height: 50px;object-fit: contain;margin-right: 10px;">
                    
                    <div class="input-field" style="float:left; margin-top: 0px;">
                        <label>
                            <input type="checkbox" class="filled-in" name="Active{{element.ID}}" 
                            
                                [(ngModel)]="element.Active"

                                (click)="onSubmitDanhHieu(element)"

                            />
                            <span></span>
                        </label>
                    </div>
                    
                    
                </div>
                
            </div>    
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>