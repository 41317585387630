import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DatPhongTrucTuyen } from 'src/app/shared/DatPhongTrucTuyen.model';
import { DatPhongTrucTuyenService } from 'src/app/shared/DatPhongTrucTuyen.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';
import { CoSoLuuTruPhong } from 'src/app/shared/CoSoLuuTruPhong.model';
import { CoSoLuuTruPhongService } from 'src/app/shared/CoSoLuuTruPhong.service';

@Component({
  selector: 'app-dat-phong-truc-tuyen-detail',
  templateUrl: './dat-phong-truc-tuyen-detail.component.html',
  styleUrls: ['./dat-phong-truc-tuyen-detail.component.css']
})
export class DatPhongTrucTuyenDetailComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;


  constructor(
    public DatPhongTrucTuyenService: DatPhongTrucTuyenService,
    public ThanhVienService: ThanhVienService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public CoSoLuuTruPhongService: CoSoLuuTruPhongService,
    public NotificationService: NotificationService,
    public dialogRef: MatDialogRef<DatPhongTrucTuyenDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ID = data["ID"] as number;
  }
  ngOnInit(): void {
    this.ThanhVienGetAllToListAsync();
    this.CoSoLuuTruGetAllToListAsync();
  }
  batDau: Date = new Date();
  ketThuc: Date = new Date();
  onChangeBatDau(value) {
    this.batDau = new Date(value);
  }
  onChangeKetThuc(value) {
    this.ketThuc = new Date(value);
  }
  onFilterThanhVien(searchString: string) {
    if (searchString.length > 0) {
      searchString = searchString.toLocaleLowerCase();
      this.ThanhVienService.listSearch = this.ThanhVienService.list.filter((item: any) =>
        item.Email.toLocaleLowerCase().indexOf(searchString) !== -1);
    }
    else {
      this.ThanhVienService.listSearch = this.ThanhVienService.list;
    }
  }
  ThanhVienGetAllToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.ThanhVienService.list = (res as ThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ThanhVienService.listSearch = this.ThanhVienService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = (res as CoSoLuuTru[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        if (this.CoSoLuuTruService.list) {
          if (this.CoSoLuuTruService.list.length > 0) {
            if (this.DatPhongTrucTuyenService.formData) {
              if (this.DatPhongTrucTuyenService.formData.ID == 0) {
                this.DatPhongTrucTuyenService.formData.CoSoLuuTruID = this.CoSoLuuTruService.list[0].ID;
              }
            }
          }
        }
        this.CoSoLuuTruPhongGetAllToListAsync();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  CoSoLuuTruPhongGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruPhongService.GetByParentIDToListAsync(this.DatPhongTrucTuyenService.formData.CoSoLuuTruID).subscribe(
      res => {
        this.CoSoLuuTruPhongService.list = (res as CoSoLuuTruPhong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onClose() {
    this.dialogRef.close();
  }
  onSubmit(form: NgForm) {
    this.isShowLoading = true;
    this.DatPhongTrucTuyenService.SaveAsync(this.DatPhongTrucTuyenService.formData).subscribe(
      res => {
        this.DatPhongTrucTuyenService.formData = res as DatPhongTrucTuyen;
        this.onClose();
        this.NotificationService.success(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
}