<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Chương trình quảng bá xúc tiến</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <input name="ID" [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.ID" type="hidden">
    <input name="ParentID" [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.ParentID" type="hidden">
    <input name="SortOrder" [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.SortOrder" type="hidden">
    <div class="col s12 m12 l5">
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="DanhMucNgonNguID">Ngôn ngữ</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="DanhMucNgonNguID" class="browser-default"
                [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.DanhMucNgonNguID">
                <mat-option *ngFor="let item of DanhMucNgonNguService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Tiêu đề</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Tiêu đề" name="Name" [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.Name"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Description">Mô tả</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Mô tả" name="Description"
                    [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.Description" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SortOrder">Sắp xếp</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input style="text-align: right;" placeholder="0" name="SortOrder"
                    [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.SortOrder" type="number">
            </div>
        </div>
        <!-- <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="NgayBatDau">Bắt đầu</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input name="NgayBatDau" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="ChuongTrinhQuangBaXucTienService.formData.NgayBatDau | date:'yyyy-MM-dd'"
                    (ngModelChange)="NgayBatDauChange($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                    type="date">

            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Kết thúc</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input name="NgayKetThuc" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="ChuongTrinhQuangBaXucTienService.formData.NgayKetThuc | date:'yyyy-MM-dd'"
                    (ngModelChange)="NgayKetThucChange($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                    type="date">

            </div>
        </div> -->
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">Tình trạng</label>
            </div>
        </div>
        <div class="col s8 m8 l8 mt-2 mb-3">
            <div class="input-field">
                <p>
                    <label>
                        <input type="checkbox" class="filled-in" name="Active"
                            [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.Active" />
                        <span>Phê duyệt / Chưa phê duyệt</span>
                    </label>
                </p>
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Code">Hình đại diện</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input type="file" (change)="changeImage($event.target.files)">
            </div>
            <div class="input-field">
                <img class="responsive-img" [src]="ChuongTrinhQuangBaXucTienService.formData.Code">
            </div>
        </div>
    </div>
    <div class="col s12 m12 l7">
        <ck-editor [config]="{'height': 400,versionCheck: false}" name="Note" [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.Note"
            skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>