<div class="row">
    <div class="col s12">
        <div class="container">
            <div id="card-stats">
                <div class="row">
                    <div class="col s12 m6 l3">
                        <div class="card animate fadeLeft">
                            <div class="card-content cyan white-text">
                                <p class="card-stats-title"><i class="material-icons">person_outline</i> Du khách</p>
                                <h4 class="card-stats-number white-text"> {{ReportService.formData.ThongKe02 | number:
                                    '1.0-0'}}</h4>
                                <p class="card-stats-compare">
                                    <i class="material-icons">keyboard_arrow_up</i>Đăng ký và kích hoạt
                                </p>
                            </div>
                            <div class="card-action cyan darken-1">
                                <div id="clients-bar" class="center-align"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m6 l3">
                        <div class="card animate fadeLeft">
                            <div class="card-content red accent-2 white-text">
                                <p class="card-stats-title"><i class="material-icons">account_balance</i>Đơn vị Tổ chức
                                </p>
                                <h4 class="card-stats-number white-text">{{ReportService.formData.ThongKe01 | number:
                                    '1.0-0'}}</h4>
                                <p class="card-stats-compare">
                                    <i class="material-icons">keyboard_arrow_up</i>Đơn vị tổ chức
                                </p>
                            </div>
                            <div class="card-action red">
                                <div id="sales-compositebar" class="center-align"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m6 l3">
                        <div class="card animate fadeRight">
                            <div class="card-content orange lighten-1 white-text">
                                <p class="card-stats-title"><i class="material-icons">local_hotel</i> Tour du lịch</p>
                                <h4 class="card-stats-number white-text">{{ReportService.formData.ThongKe03 | number:
                                    '1.0-0'}}</h4>
                                <p class="card-stats-compare">
                                    <i class="material-icons">keyboard_arrow_up</i>Đăng ký và xác nhận
                                </p>
                            </div>
                            <div class="card-action orange">
                                <div id="profit-tristate" class="center-align"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m6 l3">
                        <div class="card animate fadeRight">
                            <div class="card-content green lighten-1 white-text">
                                <p class="card-stats-title"><i class="material-icons">airport_shuttle</i> Bài viết du
                                    lịch</p>
                                <h4 class="card-stats-number white-text">{{ReportService.formData.ThongKe04 | number:
                                    '1.0-0'}}</h4>
                                <p class="card-stats-compare">
                                    <i class="material-icons">keyboard_arrow_down</i>Đăng ký, xác nhận và triển khai
                                </p>
                            </div>
                            <div class="card-action green">
                                <div id="invoice-line" class="center-align"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col s12">
            <div class="col s12 m12 l6">
                <h5 style="text-align: center;"><b>Thống kê du khách theo quốc gia</b></h5>
                <canvas baseChart [datasets]="ChartDataReport002" [labels]="ChartLabelsReport002"
                    [chartType]="ChartTypeReport002" [colors]="ChartColorsReport002" [options]="ChartOptionsReport002">
                </canvas>
                <h5 style="text-align: center;"><b>Lượng truy cập ứng dụng của du khách năm {{year}}</b></h5>
                <canvas baseChart [datasets]="ChartDataReport001" [labels]="ChartLabelsReport001"
                    [chartType]="ChartTypeReport001" [colors]="ChartColorsReport001" [options]="ChartOptionsReport001">
                </canvas>
            </div>
            <div class="col s12 m12 l6">
                <h5 style="text-align: center;"><b>Thống kê du khách theo tour du lịch</b></h5>
                <canvas baseChart [datasets]="ChartDataReport003" [labels]="ChartLabelsReport003"
                    [chartType]="ChartTypeReport003" [colors]="ChartColorsReport003" [options]="ChartOptionsReport003">
                </canvas>
                <h5 style="text-align: center;"><b>Thống kê du khách theo đơn vị lữ hành</b></h5>
                <canvas baseChart [datasets]="ChartDataReport004" [labels]="ChartLabelsReport004"
                    [chartType]="ChartTypeReport004" [colors]="ChartColorsReport004" [options]="ChartOptionsReport004">
                </canvas>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col s12">
            <!-- <div class="col s12 m12 l6">
                <table class="responsive-table">
                    <thead>
                        <tr>
                            <th data-field="1">Quận huyện</th>
                            <th data-field="2">Tổng</th>
                            <th data-field="3">Lữ hành quốc tế</th>
                            <th data-field="4">Lữ hành nội địa</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of DanhMucQuanHuyenService.list; let i = index">
                            <td>{{item.Name}}</td>
                            <td>{{item.ParentID | number: '1.0-0'}}</td>
                            <td>{{item.LastUpdatedMembershipID | number: '1.0-0'}}</td>
                            <td>{{item.CreatedMembershipID | number: '1.0-0'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
            <div class="col s12 m12 l12">
                <table class="responsive-table">
                    <thead>
                        <tr>
                            <th>Quận huyện</th>
                            <th>Tổng</th>
                            <th *ngFor="let item of DanhMucCoSoLuuTruService.list; let i = index">{{item.Name}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of ReportService.list; let i = index">
                            <td>{{item.Name}}</td>
                            <td>{{item.ThongKe | number: '1.0-0'}}</td>
                            <td>{{item.ThongKe01 | number: '1.0-0'}}</td>
                            <td>{{item.ThongKe02 | number: '1.0-0'}}</td>
                            <td>{{item.ThongKe03 | number: '1.0-0'}}</td>
                            <td>{{item.ThongKe04 | number: '1.0-0'}}</td>
                            <td>{{item.ThongKe05 | number: '1.0-0'}}</td>
                            <td>{{item.ThongKe06 | number: '1.0-0'}}</td>
                            <td>{{item.ThongKe07 | number: '1.0-0'}}</td>
                            <td>{{item.ThongKe08 | number: '1.0-0'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col s12 m12 l12">
       <iframe src="https://www.google.com/maps/d/embed?mid=1PSGTLQcrmjehC3vquvavBpXw0LKNJQ8&ehbc=2E312F" width="100%"
            height="800"></iframe>       
    </div>
</div>