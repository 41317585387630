import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhHieu } from 'src/app/shared/DanhHieu.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhHieuService extends BaseService{
    formData!: DanhHieu;
    displayColumns: string[] = ['ParentID','Code','Name', 'Note', 'SortOrder', 'Active', 'Save']; 
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhHieu";
    }

    SaveAsync(formData: DanhHieu) {
        var thanhVienID = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienID) {
            formData.LastUpdatedMembershipID = Number(thanhVienID);
        }
        let url = this.aPIURL + this.controller + '/SaveAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(formData));
        return this.httpClient.post(url, formUpload);
    }
}

