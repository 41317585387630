import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucDienThoaiLoiTat } from 'src/app/shared/DanhMucDienThoaiLoiTat.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucDienThoaiLoiTatService extends BaseService{
    displayColumns: string[] = ['Code', 'Active', 'ID', 'Save'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucDienThoaiLoiTat";
    }

    SaveAndUploadFileAsync(formData: DanhMucDienThoaiLoiTat, fileToUpload: FileList) {
        var thanhVienID = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienID) {
            formData.LastUpdatedMembershipID = Number(thanhVienID);
        }
        let url = this.aPIURL + this.controller + '/SaveAndUploadFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(formData));
        if (fileToUpload) {
            if (fileToUpload.length > 0) {
                for (var i = 0; i < fileToUpload.length; i++) {
                    formUpload.append('file[]', fileToUpload[i]);
                }
            }
        }
        return this.httpClient.post(url, formUpload);
    }

    GetByCoQuanQuanLyBaiVietIDAndDanhHieuCodeToListAsync(coQuanQuanLyBaiVietID:number,danhHieuCode:string) {
        let url = this.aPIURL + this.controller + '/GetByCoQuanQuanLyBaiVietIDAndDanhHieuCodeToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('coQuanQuanLyBaiVietID', JSON.stringify(coQuanQuanLyBaiVietID));
        formUpload.append('danhHieuCode', JSON.stringify(danhHieuCode));
        return this.httpClient.post(url, formUpload);    
    }

    GetByCoSoLuuTruIDAndDanhHieuCodeToListAsync(coSoLuuTruID:number,danhHieuCode:string) {
        let url = this.aPIURL + this.controller + '/GetByCoSoLuuTruIDAndDanhHieuCodeToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('coSoLuuTruID', JSON.stringify(coSoLuuTruID));
        formUpload.append('danhHieuCode', JSON.stringify(danhHieuCode));
        return this.httpClient.post(url, formUpload);    
    }
    
}

