import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoQuanQuanLyFile } from 'src/app/shared/CoQuanQuanLyFile.model';
import { CoQuanQuanLyFileService } from 'src/app/shared/CoQuanQuanLyFile.service';
import { CoQuanQuanLy } from 'src/app/shared/CoQuanQuanLy.model';
import { CoQuanQuanLyService } from 'src/app/shared/CoQuanQuanLy.service';
import { DanhMucVanBan } from 'src/app/shared/DanhMucVanBan.model';
import { DanhMucVanBanService } from 'src/app/shared/DanhMucVanBan.service';
import { CoQuanQuanLyFileDetailComponent } from './co-quan-quan-ly-file-detail/co-quan-quan-ly-file-detail.component';

@Component({
  selector: 'app-co-quan-quan-ly-file',
  templateUrl: './co-quan-quan-ly-file.component.html',
  styleUrls: ['./co-quan-quan-ly-file.component.css']
})
export class CoQuanQuanLyFileComponent implements OnInit {

  dataSource: MatTableDataSource<any>
    ;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;
  constructor(
    public CoQuanQuanLyFileService: CoQuanQuanLyFileService,
    public CoQuanQuanLyService: CoQuanQuanLyService,
    public DanhMucVanBanService: DanhMucVanBanService,
    public NotificationService: NotificationService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.CoQuanQuanLyGetAllToListAsync();
    this.DanhMucVanBanGetAllToListAsync();
  }
  onFilterCoQuanQuanLy(searchString: string) {
    if (searchString.length > 0) {
      searchString = searchString.toLocaleLowerCase();
      this.CoQuanQuanLyService.listSearch = this.CoQuanQuanLyService.list.filter((item: any) =>
        item.Name.toLocaleLowerCase().indexOf(searchString) !== -1);
    }
    else {
      this.CoQuanQuanLyService.listSearch = this.CoQuanQuanLyService.list;
    }
  }
  CoQuanQuanLyGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoQuanQuanLyService.GetAllToListAsync().subscribe(
      res => {
        this.CoQuanQuanLyService.list = (res as CoQuanQuanLy[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.CoQuanQuanLyService.listSearch = this.CoQuanQuanLyService.list;
        if (this.CoQuanQuanLyService.list) {
          if (this.CoQuanQuanLyService.list.length > 0) {
            this.parentID = this.CoQuanQuanLyService.list[0].ID;
          }
        }
        this.GetToListAsync();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucVanBanGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucVanBanService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucVanBanService.list = (res as DanhMucVanBan[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetToListAsync() {
    this.isShowLoading = true;
    this.CoQuanQuanLyFileService.GetByParentIDToListAsync(this.parentID).subscribe(
      res => {
        this.CoQuanQuanLyFileService.list = res as CoQuanQuanLyFile[];
        this.dataSource = new MatTableDataSource(this.CoQuanQuanLyFileService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToListAsync();
    }
  }  
  onDelete(element: CoQuanQuanLyFile) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoQuanQuanLyFileService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onAdd(ID: any) {
    this.CoQuanQuanLyFileService.GetByIDAsync(ID).subscribe(
      res => {
        this.CoQuanQuanLyFileService.formData = res as CoQuanQuanLyFile;          
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoQuanQuanLyFileDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.GetToListAsync();
        });
      },
      err => {
      }
    );
  }
}
