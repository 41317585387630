import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruMonAn } from 'src/app/shared/CoSoLuuTruMonAn.model';
import { CoSoLuuTruMonAnService } from 'src/app/shared/CoSoLuuTruMonAn.service';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';
import { CoSoLuuTruMonAnDetailComponent } from './co-so-luu-tru-mon-an-detail/co-so-luu-tru-mon-an-detail.component';

@Component({
  selector: 'app-mon-an',
  templateUrl: './mon-an.component.html',
  styleUrls: ['./mon-an.component.css']
})
export class MonAnComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;
  queryString: string = environment.InitializationString;
  ID: number = environment.InitializationNumber;
  constructor(
    public router: Router,
    public CoSoLuuTruMonAnService: CoSoLuuTruMonAnService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public NotificationService: NotificationService,
    public dialog: MatDialog
  ) { 
    this.CoSoLuuTruGetAllToListAsync();
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        if (this.queryString.split("/").length == 3) {
          this.ID = Number(this.queryString.split("/")[2]);
        }
        this.onSearch();
      }
    });
  }

  ngOnInit(): void {  
  }

  onFilterCoSoLuuTru(searchString: string) {
    if (searchString.length > 0) {
      searchString = searchString.toLocaleLowerCase();
      this.CoSoLuuTruService.listSearch = this.CoSoLuuTruService.list.filter((item: any) =>
        item.TenDangKyKinhDoanh.toLocaleLowerCase().indexOf(searchString) !== -1);
    }
    else {
      this.CoSoLuuTruService.listSearch = this.CoSoLuuTruService.list;
    }
  }
  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = (res as CoSoLuuTru[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.CoSoLuuTruService.listSearch = this.CoSoLuuTruService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }


  GetToListAsync() {    
    this.isShowLoading = true;
    if (this.ID > 0) {
      this.CoSoLuuTruMonAnService.GetByIDToListAsync(this.ID).subscribe(
        res => {
          this.CoSoLuuTruMonAnService.list = res as CoSoLuuTruMonAn[];          
          this.dataSource = new MatTableDataSource(this.CoSoLuuTruMonAnService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          if (this.CoSoLuuTruMonAnService.list) {
            if (this.CoSoLuuTruMonAnService.list.length > 0) {
              this.parentID = this.CoSoLuuTruMonAnService.list[0].ParentID;
            }
          }
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    else {
      this.CoSoLuuTruMonAnService.GetByParentIDToListAsync(this.parentID).subscribe(
        res => {
          this.CoSoLuuTruMonAnService.list = res as CoSoLuuTruMonAn[];
          this.dataSource = new MatTableDataSource(this.CoSoLuuTruMonAnService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }    
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToListAsync();
    }
  }
  MonAnAdd(ID: any) {
    this.CoSoLuuTruMonAnService.GetByIDAsync(ID).subscribe(
      res => {
        this.CoSoLuuTruMonAnService.formData = res as CoSoLuuTruMonAn;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoSoLuuTruMonAnDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.onSearch();
        });
      },
      err => {
      }
    );
  }
  onDelete(element: CoSoLuuTruMonAn) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruMonAnService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
}