import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucDienThoaiTieuDe } from 'src/app/shared/DanhMucDienThoaiTieuDe.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucDienThoaiTieuDeService extends BaseService{
    displayColumns: string[] = ['ParentID','Code','Name', 'Note', 'SortOrder', 'Active', 'Save']; 
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucDienThoaiTieuDe";
    }
}

