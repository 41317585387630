<div class="row">
    <div class="col s12 m12 l6">
        <h5 style="text-align: center;"><b>Lượng truy cập ứng dụng của du khách năm {{year}}</b></h5>
        <canvas baseChart [datasets]="ChartDataReport001" [labels]="ChartLabelsReport001"
            [chartType]="ChartTypeReport001" [colors]="ChartColorsReport001" [options]="ChartOptionsReport001">
        </canvas>
    </div>
</div>
<br />
<br />
<br />