import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';
import { DanhMucNgonNgu } from 'src/app/shared/DanhMucNgonNgu.model';
import { DanhMucNgonNguService } from 'src/app/shared/DanhMucNgonNgu.service';

@Component({
  selector: 'app-danh-muc-thanh-vien',
  templateUrl: './danh-muc-thanh-vien.component.html',
  styleUrls: ['./danh-muc-thanh-vien.component.css']
})
export class DanhMucThanhVienComponent implements OnInit {

  dataSource: MatTableDataSource<any>
    ;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  constructor(
    public DanhMucThanhVienService: DanhMucThanhVienService,
    public DanhMucNgonNguService: DanhMucNgonNguService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.DanhMucNgonNguGetAllToListAsync();
    this.GetAllAndEmptyToListAsync();
  }
  DanhMucNgonNguGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucNgonNguService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucNgonNguService.list = (res as DanhMucNgonNgu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));        
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  GetAllAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.DanhMucThanhVienService.GetAllAndEmptyToListAsync().subscribe(
      res => {
        this.DanhMucThanhVienService.list = res as DanhMucThanhVien[];
        this.dataSource = new MatTableDataSource(this.DanhMucThanhVienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetAllAndEmptyToListAsync();
    }
  }
  onSave(element: DanhMucThanhVien) {
    this.DanhMucThanhVienService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: DanhMucThanhVien) {
    if (confirm(environment.DeleteConfirm)) {
      this.DanhMucThanhVienService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
}