import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoQuanQuanLyBaiViet } from 'src/app/shared/CoQuanQuanLyBaiViet.model';
import { CoQuanQuanLyBaiVietService } from 'src/app/shared/CoQuanQuanLyBaiViet.service';
import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { DanhMucBaiVietService } from 'src/app/shared/DanhMucBaiViet.service';
import { DanhMucNgonNgu } from 'src/app/shared/DanhMucNgonNgu.model';
import { DanhMucNgonNguService } from 'src/app/shared/DanhMucNgonNgu.service';
import { CoQuanQuanLyBaiVietDetailComponent } from './co-quan-quan-ly-bai-viet-detail/co-quan-quan-ly-bai-viet-detail.component';


@Component({
  selector: 'app-co-quan-quan-ly-bai-viet',
  templateUrl: './co-quan-quan-ly-bai-viet.component.html',
  styleUrls: ['./co-quan-quan-ly-bai-viet.component.css']
})
export class CoQuanQuanLyBaiVietComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  danhMucBaiVietID: number = environment.InitializationNumber;
  constructor(
    public CoQuanQuanLyBaiVietService: CoQuanQuanLyBaiVietService,
    public DanhMucBaiVietService: DanhMucBaiVietService,
    public DanhMucNgonNguService: DanhMucNgonNguService,
    public NotificationService: NotificationService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.DanhMucNgonNguGetAllToListAsync();
    this.DanhMucBaiVietGetAllToListAsync();    
  }
  DanhMucNgonNguGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucNgonNguService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucNgonNguService.list = (res as DanhMucNgonNgu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));        
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucBaiVietGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucBaiVietService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucBaiVietService.list = (res as DanhMucBaiViet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)).filter(x => x.ParentID == environment.DanhMucNgonNguTiengVietID);
        if (this.DanhMucBaiVietService.list) {
          if (this.DanhMucBaiVietService.list.length > 0) {
            this.danhMucBaiVietID = this.DanhMucBaiVietService.list[0].ID;            
            this.onSearch();
          }
        }     
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  GetAllToListAsync() {
    this.isShowLoading = true;
    this.CoQuanQuanLyBaiVietService.GetByDanhMucBaiVietIDToListAsync(this.danhMucBaiVietID).subscribe(
      res => {
        this.CoQuanQuanLyBaiVietService.list = (res as CoQuanQuanLyBaiViet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.dataSource = new MatTableDataSource(this.CoQuanQuanLyBaiVietService.list);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetAllToListAsync();
    }
  }
  onSave(element: CoQuanQuanLyBaiViet) {
    this.CoQuanQuanLyBaiVietService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: CoQuanQuanLyBaiViet) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoQuanQuanLyBaiVietService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onAdd(ID: any) {
    this.CoQuanQuanLyBaiVietService.GetByIDAsync(ID).subscribe(
      res => {
        this.CoQuanQuanLyBaiVietService.formData = res as CoQuanQuanLyBaiViet;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoQuanQuanLyBaiVietDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe((data) => {
          
          this.CoQuanQuanLyBaiVietService.formData  = data as CoQuanQuanLyBaiViet;
          if(this.CoQuanQuanLyBaiVietService.formData.ID > 0){
            //console.log("dataTiengAnh.ID ",this.CoQuanQuanLyBaiVietService.formData.ID );
              const dialogConfig2 = new MatDialogConfig();
              dialogConfig2.disableClose = true;
              dialogConfig2.autoFocus = true;
              dialogConfig2.width = environment.DialogConfigWidth;
              dialogConfig2.data = { ID: this.CoQuanQuanLyBaiVietService.formData.ID };
              dialogConfig2.panelClass = 'custom-modalbox';
              const dialog2 = this.dialog.open(CoQuanQuanLyBaiVietDetailComponent, dialogConfig2);
              dialog2.afterClosed().subscribe((data) => {
                this.ngOnInit();
              });
          }  
          this.ngOnInit();
        });
      },
      err => {
      }
    );
  }

  onAddTiengAnh(element: CoQuanQuanLyBaiViet) {
    this.CoQuanQuanLyBaiVietService.GetByMapNgonNguAsync(element.MapNgonNgu,environment.DanhMucNgonNguTiengAnhID).subscribe(
      res => {

        var dataTiengAnh = res as CoQuanQuanLyBaiViet;
        
        this.CoQuanQuanLyBaiVietService.formData = dataTiengAnh;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: element.ID };
        const dialog = this.dialog.open(CoQuanQuanLyBaiVietDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe((data) => {
          
          this.CoQuanQuanLyBaiVietService.formData  = data as CoQuanQuanLyBaiViet

          this.ngOnInit();
        });
      },
      err => {
      }
    );
  }
}
