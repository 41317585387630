<div class="row">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Du khách
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="#" style="color: #ffffff;">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Du khách
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <div id="basic-tabs" class="card card card-default scrollspy">
                            <div class="card-content">
                                <!-- <div class="card-title">
                                    <div class="row">
                                        <div class="col s12 m12 l4">
                                        </div>
                                        <div class="col s12 m12 l8">
                                            <ul class="tabs">
                                                <li class="tab col m4 s12 p-0"><a class="active p-0" href="#1">Theo Quận
                                                        huyện</a></li>
                                                <li class="tab col m4 s12 p-0"><a class="p-0" href="#2">Theo Đơn vị tổ
                                                        chức</a>
                                                </li>
                                                <li class="tab col m4 s12 p-0"><a class="p-0" href="#3">Theo Quốc
                                                        gia</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->
                                <h5>Theo Quận huyện</h5>
                                <div class="col s12 m12 l12">
                                    <div class="col s12 m12 l2">
                                        <input matInput [ngModel]="dateTimeBegin | date:'yyyy-MM-dd'"
                                            (ngModelChange)="onChangeDateTimeBegin($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                            class="mat-input-element mat-form-field-autofill-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col s12 m12 l2">
                                        <input matInput [ngModel]="dateTimeEnd | date:'yyyy-MM-dd'"
                                            (ngModelChange)="onChangeDateTimeEnd($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                            class="mat-input-element mat-form-field-autofill-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col s12 m12 l6">
                                        <input placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col s12 m12 l2">
                                        <a title="Tìm" (click)="onSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                                        <a title="In biểu mẫu"
                                            class="btn-floating waves-effect waves-light green darken-1"
                                            (click)="onPrint001()"><i
                                                class="material-icons">print</i></a>&nbsp;&nbsp;&nbsp;
                                        <a title="Download excel" (click)="onDownloadExcel001()"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">get_app</i></a>&nbsp;&nbsp;&nbsp;

                                    </div>
                                    <div class="KhungOverflow">
                                        <table class="responsive-table">
                                            <thead>
                                                <tr>
                                                    <th>Quận huyện</th>
                                                    <th>Năm</th>
                                                    <th>01</th>
                                                    <th>02</th>
                                                    <th>03</th>
                                                    <th>04</th>
                                                    <th>05</th>
                                                    <th>06</th>
                                                    <th>07</th>
                                                    <th>08</th>
                                                    <th>09</th>
                                                    <th>10</th>
                                                    <th>11</th>
                                                    <th>12</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of ReportService.list001; let i = index">
                                                    <td><b>{{item.Name}}</b></td>
                                                    <td>{{item.ThongKe | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe01 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe02 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe03 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe04 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe05 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe06 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe07 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe08 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe09 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe10 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe11 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe12 | number: '1.0-0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                               
                                <h5>Theo Đơn vị tổ chức</h5>
                                <div class="col s12 m12 l12">
                                    <div class="col s12 m12 l2">
                                        <input matInput [ngModel]="dateTimeBegin | date:'yyyy-MM-dd'"
                                            (ngModelChange)="onChangeDateTimeBegin($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                            class="mat-input-element mat-form-field-autofill-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col s12 m12 l2">
                                        <input matInput [ngModel]="dateTimeEnd | date:'yyyy-MM-dd'"
                                            (ngModelChange)="onChangeDateTimeEnd($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                            class="mat-input-element mat-form-field-autofill-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col s12 m12 l6">
                                        <input placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col s12 m12 l2">
                                        <a title="Tìm" (click)="onSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                                        <a title="In biểu mẫu"
                                            class="btn-floating waves-effect waves-light green darken-1"
                                            (click)="onPrint002()"><i
                                                class="material-icons">print</i></a>&nbsp;&nbsp;&nbsp;
                                        <a title="Download excel" (click)="onDownloadExcel002()"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">get_app</i></a>&nbsp;&nbsp;&nbsp;

                                    </div>
                                    <div class="KhungOverflow">
                                        <table class="responsive-table">
                                            <thead>
                                                <tr>
                                                    <th>Đơn vị tổ chức</th>
                                                    <th>Năm</th>
                                                    <th>01</th>
                                                    <th>02</th>
                                                    <th>03</th>
                                                    <th>04</th>
                                                    <th>05</th>
                                                    <th>06</th>
                                                    <th>07</th>
                                                    <th>08</th>
                                                    <th>09</th>
                                                    <th>10</th>
                                                    <th>11</th>
                                                    <th>12</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of ReportService.list002; let i = index">
                                                    <td><b>{{item.Name}}</b></td>
                                                    <td>{{item.ThongKe | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe01 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe02 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe03 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe04 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe05 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe06 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe07 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe08 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe09 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe10 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe11 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe12 | number: '1.0-0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                <h5>Theo Quốc gia</h5>
                                <div class="col s12 m12 l12">
                                    <div class="col s12 m12 l2">
                                        <input matInput [ngModel]="dateTimeBegin | date:'yyyy-MM-dd'"
                                            (ngModelChange)="onChangeDateTimeBegin($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                            class="mat-input-element mat-form-field-autofill-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col s12 m12 l2">
                                        <input matInput [ngModel]="dateTimeEnd | date:'yyyy-MM-dd'"
                                            (ngModelChange)="onChangeDateTimeEnd($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                            class="mat-input-element mat-form-field-autofill-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col s12 m12 l6">
                                        <input placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col s12 m12 l2">
                                        <a title="Tìm" (click)="onSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                                        <a title="In biểu mẫu"
                                            class="btn-floating waves-effect waves-light green darken-1"
                                            (click)="onPrint003()"><i
                                                class="material-icons">print</i></a>&nbsp;&nbsp;&nbsp;
                                        <a title="Download excel" (click)="onDownloadExcel003()"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">get_app</i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <table class="responsive-table">
                                            <thead>
                                                <tr>
                                                    <th>Quốc gia</th>
                                                    <th>Năm</th>
                                                    <th>01</th>
                                                    <th>02</th>
                                                    <th>03</th>
                                                    <th>04</th>
                                                    <th>05</th>
                                                    <th>06</th>
                                                    <th>07</th>
                                                    <th>08</th>
                                                    <th>09</th>
                                                    <th>10</th>
                                                    <th>11</th>
                                                    <th>12</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of ReportService.list003; let i = index">
                                                    <td><b>{{item.Name}}</b></td>
                                                    <td>{{item.ThongKe | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe01 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe02 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe03 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe04 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe05 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe06 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe07 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe08 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe09 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe10 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe11 | number: '1.0-0'}}</td>
                                                    <td>{{item.ThongKe12 | number: '1.0-0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>