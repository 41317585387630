import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoQuanQuanLy } from 'src/app/shared/CoQuanQuanLy.model';
import { CoQuanQuanLyService } from 'src/app/shared/CoQuanQuanLy.service';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
@Component({
  selector: 'app-co-quan-quan-ly-info',
  templateUrl: './co-quan-quan-ly-info.component.html',
  styleUrls: ['./co-quan-quan-ly-info.component.css']
})
export class CoQuanQuanLyInfoComponent implements OnInit {

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  URLSub: string = environment.DomainDestination + "CoQuanQuanLyInfo";

  constructor(
    public router: Router,
    public CoQuanQuanLyService: CoQuanQuanLyService,
    public NotificationService: NotificationService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        this.GetByQueryString();
      }
    });
  }

  ngOnInit(): void {
  }


  DanhMucTinhThanhGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = res as DanhMucTinhThanh[];
        if (this.DanhMucTinhThanhService.list.length > 0) {
          this.DanhMucTinhThanhService.list[0].ID = environment.InitializationNumber;
        }
        this.DanhMucTinhThanhService.list = this.DanhMucTinhThanhService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucQuanHuyenGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync(this.CoQuanQuanLyService.formData.DanhMucTinhThanhID).subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = res as DanhMucQuanHuyen[];
        this.DanhMucQuanHuyenService.list = this.DanhMucQuanHuyenService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync(this.CoQuanQuanLyService.formData.DanhMucQuanHuyenID).subscribe(
      res => {
        this.DanhMucXaPhuongService.list = res as DanhMucXaPhuong[];
        this.DanhMucXaPhuongService.list = this.DanhMucXaPhuongService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetByQueryString() {
    this.isShowLoading = true;
    this.CoQuanQuanLyService.GetByIDStringAsync(this.queryString).subscribe(res => {
      this.CoQuanQuanLyService.formData = res as CoQuanQuanLy;
      if (this.CoQuanQuanLyService.formData) {
        console.log("formData:", JSON.stringify(this.CoQuanQuanLyService.formData));
        this.CoQuanQuanLyService.formData.DanhMucTinhThanhID = environment.HoChiMinhCityID;
        this.DanhMucQuanHuyenGetAllToListAsync();
        if (this.CoQuanQuanLyService.formData.ID > 0) {
          this.DanhMucXaPhuongGetAllToListAsync();
        }
        else {
          this.CoQuanQuanLyService.formData.DanhMucQuanHuyenID = environment.InitializationNumber;
          this.CoQuanQuanLyService.formData.DanhMucXaPhuongID = environment.InitializationNumber;
        }
      }
      this.isShowLoading = false;
    });
  }
  onSubmit(form: NgForm) {
    this.isShowLoading = true;
    this.CoQuanQuanLyService.SaveAsync(form.value).subscribe(
      res => {
        if (this.CoQuanQuanLyService.formData.ID == 0) {
          this.CoQuanQuanLyService.formData = res as CoQuanQuanLy;
          let url = this.URLSub + "/" + this.CoQuanQuanLyService.formData.ID;
          window.location.href = this.URLSub + "/" + this.CoQuanQuanLyService.formData.ID;
        }
        else {
          this.GetByQueryString();
          this.NotificationService.warn(environment.SaveSuccess);
        }
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
}
