import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { UploadService } from 'src/app/shared/Upload.service';

import { DanhMucCoSoLuuTru } from 'src/app/shared/DanhMucCoSoLuuTru.model';
import { DanhMucCoSoLuuTruService } from 'src/app/shared/DanhMucCoSoLuuTru.service';


import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { DanhMucBaiVietService } from 'src/app/shared/DanhMucBaiViet.service';

import { CoQuanQuanLyBaiViet } from 'src/app/shared/CoQuanQuanLyBaiViet.model';
import { CoQuanQuanLyBaiVietService } from 'src/app/shared/CoQuanQuanLyBaiViet.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  isShowLoading: boolean = false;

  isCoQuanQuanLyBaiViet: boolean = false;  
  danhMucBaiVietID:number = environment.InitializationNumber;

  isCoSoLuuTru: boolean = false;  
  isCoSoLuuTru_KhachSan: boolean = false;
  isCoSoLuuTru_DiemDuLich: boolean = false;
  isCoSoLuuTru_DonViLuHanh: boolean = false;
  isCoSoLuuTru_NhaHang: boolean = false;
  danhMucCoSoLuuTruID:number = environment.InitializationNumber;

  

  isDanhMucTinhThanh: boolean = false;
  isDanhMucQuanHuyen: boolean = false;
  isDanhMucXaPhuong: boolean = false;
  isDanhMucQuocGia: boolean = false;
  isDanhMucCoSoLuuTru: boolean = false;
  isCoSoLuuTruDichVu: boolean = false;
  isCoSoLuuTruTour: boolean = false;  
  isCoSoLuuTruPhong: boolean = false;  
  isCoSoLuuTruMonAn: boolean = false;  
  isCoSoLuuTruHoatDong: boolean = false;  
  isCoSoLuuTruSuKien: boolean = false;  
  isCoSoLuuTruSocial: boolean = false;  
  
  excelCoSoLuuTruURL: string = environment.APIRootURL + environment.Download + "/CoSoLuuTru.xlsx";
  excelCoSoLuuTru_KhachSanURL: string = environment.APIRootURL + environment.Download + "/CoSoLuuTru_KhachSan.xlsx";
  excelCoSoLuuTru_DiemDuLichURL: string = environment.APIRootURL + environment.Download + "/CoSoLuuTru_DiemDuLich.xlsx";
  excelCoSoLuuTru_DonViLuHanhURL: string = environment.APIRootURL + environment.Download + "/CoSoLuuTru_DonViLuHanh.xlsx";
  excelCoSoLuuTru_NhaHangURL: string = environment.APIRootURL + environment.Download + "/CoSoLuuTru_NhaHang.xlsx";

  excelDanhMucTinhThanhURL: string = environment.APIRootURL + environment.Download + "/DanhMucTinhThanh.xlsx";
  excelDanhMucQuanHuyenURL: string = environment.APIRootURL + environment.Download + "/DanhMucQuanHuyen.xlsx"; 
  excelDanhMucXaPhuongURL : string = environment.APIRootURL + environment.Download + "/DanhMucXaPhuong.xlsx";
  excelDanhMucQuocGiaURL : string = environment.APIRootURL + environment.Download + "/DanhMucQuocGia.xlsx"; 
  excelDanhMucCoSoLuuTruURL : string = environment.APIRootURL + environment.Download + "/DanhMucCoSoLuuTruDichVu.xlsx"; 
  excelCoSoLuuTruDichVuURL : string = environment.APIRootURL + environment.Download + "/CoSoLuuTruDichVu.xlsx"; 
  excelCoSoLuuTruTourURL : string = environment.APIRootURL + environment.Download + "/CoSoLuuTruTour.xlsx"; 
  excelCoSoLuuTruPhongURL : string = environment.APIRootURL + environment.Download + "/CoSoLuuTruPhong.xlsx"; 
  excelCoSoLuuTruMonAnURL : string = environment.APIRootURL + environment.Download + "/CoSoLuuTruMonAn.xlsx"; 
  excelCoSoLuuTruHoatDongURL : string = environment.APIRootURL + environment.Download + "/CoSoLuuTruHoatDong.xlsx"; 
  excelCoSoLuuTruSuKienURL : string = environment.APIRootURL + environment.Download + "/CoSoLuuTruSuKien.xlsx"; 
  excelCoSoLuuTruSocialURL : string = environment.APIRootURL + environment.Download + "/CoSoLuuTruSocial.xlsx"; 

  excelCoQuanQuanLyBaiVietURL: string = environment.APIRootURL + environment.Download + "/CoQuanQuanLyBaiViet.xlsx";
  
  @ViewChild('uploadCoQuanQuanLyBaiViet') uploadCoQuanQuanLyBaiViet!: ElementRef;  

  @ViewChild('uploadCoSoLuuTru') uploadCoSoLuuTru!: ElementRef;  
  @ViewChild('uploadCoSoLuuTru_KhachSan') uploadCoSoLuuTru_KhachSan!: ElementRef;  
  @ViewChild('uploadCoSoLuuTru_DiemDuLich') uploadCoSoLuuTru_DiemDuLich!: ElementRef; 
  @ViewChild('uploadCoSoLuuTru_DonViLuHanh') uploadCoSoLuuTru_DonViLuHanh!: ElementRef; 
  @ViewChild('uploadCoSoLuuTru_NhaHang') uploadCoSoLuuTru_NhaHang!: ElementRef; 

  @ViewChild('uploadDanhMucTinhThanh') uploadDanhMucTinhThanh!: ElementRef;  
  @ViewChild('uploadDanhMucQuanHuyen') uploadDanhMucQuanHuyen!: ElementRef;  
  @ViewChild('uploadDanhMucXaPhuong') uploadDanhMucXaPhuong!: ElementRef; 
  @ViewChild('uploadDanhMucQuocGia') uploadDanhMucQuocGia!: ElementRef; 
  @ViewChild('uploadDanhMucCoSoLuuTru') uploadDanhMucCoSoLuuTru!: ElementRef; 
  @ViewChild('uploadCoSoLuuTruDichVu') uploadCoSoLuuTruDichVu!: ElementRef; 
  @ViewChild('uploadCoSoLuuTruTour') uploadCoSoLuuTruTour!: ElementRef; 
  @ViewChild('uploadCoSoLuuTruPhong') uploadCoSoLuuTruPhong!: ElementRef; 
  @ViewChild('uploadCoSoLuuTruMonAn') uploadCoSoLuuTruMonAn!: ElementRef; 
  @ViewChild('uploadCoSoLuuTruHoatDong') uploadCoSoLuuTruHoatDong!: ElementRef; 
  @ViewChild('uploadCoSoLuuTruSuKien') uploadCoSoLuuTruSuKien!: ElementRef; 
  @ViewChild('uploadCoSoLuuTruSocial') uploadCoSoLuuTruSocial!: ElementRef; 

  @ViewChild('uploadCoQuanQuanLyBaiVietSocial') uploadCoQuanQuanLyBaiVietSocial!: ElementRef; 
  
  dataSource: MatTableDataSource<any>;  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(    
    public UploadService: UploadService,    
    public NotificationService: NotificationService,
    public DanhMucCoSoLuuTruService: DanhMucCoSoLuuTruService,
    public DanhMucBaiVietService: DanhMucBaiVietService,
    public CoQuanQuanLyBaiVietService: CoQuanQuanLyBaiVietService,
  ) { }

  ngOnInit(): void {    
    this.DanhMucCoSoLuuTruGetAllToListAsync();
    this.DanhMucBaiVietGetAllToListAsync();
  }    


  DanhMucBaiVietGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucBaiVietService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucBaiVietService.list = res as DanhMucBaiViet[];
        this.danhMucBaiVietID = environment.InitializationNumber;
        this.DanhMucBaiVietService.list = this.DanhMucBaiVietService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)).filter(x => x.ParentID == environment.DanhMucNgonNguTiengVietID);
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  changeSelectDanhMucBaiViet(){
    if(this.uploadCoSoLuuTru.nativeElement.files[0]){
      this.isCoQuanQuanLyBaiViet = true;
    }
  }
  changeCoQuanQuanLyBaiViet(files: FileList) {
    if (files) {
      if(this.danhMucBaiVietID>0){
        this.isCoQuanQuanLyBaiViet = true;
      }
    }
  }
  onSubmitCoQuanQuanLyBaiViet() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoQuanQuanLyBaiViet.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoQuanQuanLyBaiVietListByExcelFileAsync(fileToUpload,this.danhMucBaiVietID.toString()).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  
  

  DanhMucCoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucCoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucCoSoLuuTruService.list = res as DanhMucCoSoLuuTru[];
        this.danhMucCoSoLuuTruID = environment.InitializationNumber;
        this.DanhMucCoSoLuuTruService.list = this.DanhMucCoSoLuuTruService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)).filter(x => x.ParentID == environment.DanhMucNgonNguTiengVietID);
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  changeSelectDanhMucCoSoLuuTru(){
    if(this.uploadCoSoLuuTru.nativeElement.files[0]){
      this.isCoSoLuuTru = true;
    }
  }
  changeCoSoLuuTru(files: FileList) {
    if (files) {
      if(this.danhMucCoSoLuuTruID>0){
        this.isCoSoLuuTru = true;
      }
    }
  }
  onSubmitCoSoLuuTru() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTru.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTruListByExcelFileAsync(fileToUpload,this.danhMucCoSoLuuTruID.toString()).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  

  changeCoSoLuuTru_NhaHang(files: FileList) {
    if (files) {
      this.isCoSoLuuTru_NhaHang = true;
    }
  }
  onSubmitCoSoLuuTru_NhaHang() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTru_NhaHang.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTru_NhaHangListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  
  
  changeCoSoLuuTru_DonViLuHanh(files: FileList) {
    if (files) {
      this.isCoSoLuuTru_DonViLuHanh = true;
    }
  }
  onSubmitCoSoLuuTru_DonViLuHanh() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTru_DonViLuHanh.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTru_DonViLuHanhListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  

  changeCoSoLuuTru_DiemDuLich(files: FileList) {
    if (files) {
      this.isCoSoLuuTru_DiemDuLich = true;
    }
  }
  onSubmitCoSoLuuTru_DiemDuLich() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTru_DiemDuLich.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTru_DiemDuLichListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  


  changeCoSoLuuTru_KhachSan(files: FileList) {
    if (files) {
      this.isCoSoLuuTru_KhachSan = true;
    }
  }
  onSubmitCoSoLuuTru_KhachSan() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTru_KhachSan.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTru_KhachSanListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  


  changeDanhMucTinhThanh(files: FileList) {
    if (files) {
      this.isDanhMucTinhThanh = true;
    }
  }
  onSubmitDanhMucTinhThanh() {
    let fileToUpload: File;
    fileToUpload = this.uploadDanhMucTinhThanh.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostDanhMucTinhThanhListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  

  changeDanhMucQuanHuyen(files: FileList) {
    if (files) {
      this.isDanhMucQuanHuyen = true;
    }
  }
  onSubmitDanhMucQuanHuyen() {
    let fileToUpload: File;
    fileToUpload = this.uploadDanhMucQuanHuyen.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostDanhMucQuanHuyenListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  

  changeDanhMucXaPhuong(files: FileList) {
    if (files) {
      this.isDanhMucXaPhuong = true;
    }
  }
  onSubmitDanhMucXaPhuong() {
    let fileToUpload: File;
    fileToUpload = this.uploadDanhMucXaPhuong.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostDanhMucXaPhuongListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  
  changeDanhMucQuocGia(files: FileList) {
    if (files) {
      this.isDanhMucQuocGia = true;
    }
  }
  onSubmitDanhMucQuocGia() {
    let fileToUpload: File;
    fileToUpload = this.uploadDanhMucQuocGia.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostDanhMucQuocGiaListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  
  changeDanhMucCoSoLuuTru(files: FileList) {
    if (files) {
      this.isDanhMucCoSoLuuTru = true;
    }
  }
  onSubmitDanhMucCoSoLuuTru() {
    let fileToUpload: File;
    fileToUpload = this.uploadDanhMucCoSoLuuTru.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostDanhMucCoSoLuuTruListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  

  changeCoSoLuuTruDichVu(files: FileList) {
    if (files) {
      this.isCoSoLuuTruDichVu = true;
    }
  }
  onSubmitCoSoLuuTruDichVu() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTruDichVu.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTruDichVuListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  

  changeCoSoLuuTruTour(files: FileList) {
    if (files) {
      this.isCoSoLuuTruTour = true;
    }
  }
  onSubmitCoSoLuuTruTour() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTruTour.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTruTourListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  

  changeCoSoLuuTruPhong(files: FileList) {
    if (files) {
      this.isCoSoLuuTruPhong = true;
    }
  }
  onSubmitCoSoLuuTruPhong() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTruPhong.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTruPhongListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  
  
  changeCoSoLuuTruMonAn(files: FileList) {
    if (files) {
      this.isCoSoLuuTruMonAn = true;
    }
  }
  onSubmitCoSoLuuTruMonAn() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTruMonAn.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTruMonAnListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  

  changeCoSoLuuTruHoatDong(files: FileList) {
    if (files) {
      this.isCoSoLuuTruHoatDong = true;
    }
  }
  onSubmitCoSoLuuTruHoatDong() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTruHoatDong.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTruHoatDongListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  

  changeCoSoLuuTruSuKien(files: FileList) {
    if (files) {
      this.isCoSoLuuTruSuKien = true;
    }
  }
  onSubmitCoSoLuuTruSuKien() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTruSuKien.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTruSuKienListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  

  changeCoSoLuuTruSocial(files: FileList) {
    if (files) {
      this.isCoSoLuuTruSocial = true;
    }
  }
  onSubmitCoSoLuuTruSocial() {
    let fileToUpload: File;
    fileToUpload = this.uploadCoSoLuuTruSocial.nativeElement.files[0];
    this.isShowLoading = true;
    this.UploadService.PostCoSoLuuTruSocialListByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }  
}

