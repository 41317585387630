import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Report } from 'src/app/shared/Report.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ReportService extends BaseService{
    displayColumns: string[] = ['Code', 'Active', 'Save']; 
    list: Report[] | undefined;   
    list001: Report[] | undefined;   
    list002: Report[] | undefined;   
    list003: Report[] | undefined;   
    formData!: Report;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "Report";
        this.initializationFormData();
    } 
    initializationFormData() {
        this.formData = {
        }
        this.list = [];
    }
    Report001ToHTMLAsync(list: Report[]) {
        let url = this.aPIURL + this.controller + '/Report001ToHTMLAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(list));
        return this.httpClient.post(url, formUpload);
    }
    Report0001Async() {
        let url = this.aPIURL + this.controller + '/Report0001Async';
        const formUpload: FormData = new FormData();        
        return this.httpClient.post(url, formUpload);
    }
    Report0002ToListAsync() {
        let url = this.aPIURL + this.controller + '/Report0002ToListAsync';
        const formUpload: FormData = new FormData();        
        return this.httpClient.post(url, formUpload);
    }
}

