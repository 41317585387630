import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoQuanQuanLyBaiViet } from 'src/app/shared/CoQuanQuanLyBaiViet.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoQuanQuanLyBaiVietService extends BaseService{
    //displayColumns: string[] = ['DanhMucNgonNguID', 'Code', 'Name', 'Active', 'Save'];
    displayColumns: string[] = [ 'Code', 'Name', 'Active', 'Save'];
    displayColumnsChuyenBaiViet: string[] = ['Code', 'Name', 'Select'];
    list: CoQuanQuanLyBaiViet[] | undefined;
    listSearch: CoQuanQuanLyBaiViet[] | undefined;
    listSearch001: CoQuanQuanLyBaiViet[] | undefined;
    formData!: CoQuanQuanLyBaiViet;
    
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoQuanQuanLyBaiViet";
    }
    SaveAndUploadFileAsync(formData: CoQuanQuanLyBaiViet, fileToUpload: FileList) {
        var thanhVienID = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienID) {
            formData.LastUpdatedMembershipID = Number(thanhVienID);            
        }
        let url = this.aPIURL + this.controller + '/SaveAndUploadFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(formData));
        if (fileToUpload) {
            if (fileToUpload.length > 0) {
                for (var i = 0; i < fileToUpload.length; i++) {
                    formUpload.append('file[]', fileToUpload[i]);
                }
            }
        }
        return this.httpClient.post(url, formUpload);
    }
    GetByDanhMucBaiVietIDToListAsync(danhMucBaiVietID: number){
        let url = this.aPIURL + this.controller + '/GetByDanhMucBaiVietIDToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('danhMucBaiVietID', JSON.stringify(danhMucBaiVietID));              
        return this.httpClient.post(url, formUpload);
    }

    GetByMapNgonNguAsync(mapNgonNgu: string,danhMucNgonNguID: number){
        let url = this.aPIURL + this.controller + '/GetByMapNgonNguAsync';
        const formUpload: FormData = new FormData();       
        formUpload.append('mapNgonNgu', JSON.stringify(mapNgonNgu));    
        formUpload.append('danhMucNgonNguID', JSON.stringify(danhMucNgonNguID));              
        return this.httpClient.post(url, formUpload);
    }
}

