<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Tài liệu đính kèm</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <input name="ID" [(ngModel)]="CoSoLuuTruFileService.formData.ID" type="hidden">
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="ParentID">Danh mục</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <mat-select
                    style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                    name="DanhMucGiayChungNhanID" class="browser-default" (selectionChange)="DanhMucGiayChungNhanSelectionChange()"
                    [(ngModel)]="CoSoLuuTruFileService.formData.DanhMucGiayChungNhanID">
                    <mat-option *ngFor="let item of DanhMucGiayChungNhanService.list" [value]="item.ID">
                        {{item.Name}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Tiêu đề</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Tiêu đề" name="Name" [(ngModel)]="CoSoLuuTruFileService.formData.Name" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Bắt đầu</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input name="NgayBatDau" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="CoSoLuuTruFileService.formData.BatDau | date:'yyyy-MM-dd'"
                    (ngModelChange)="NgayBatDauChange($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date">

            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Kết thúc</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input name="NgayKetThuc" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="CoSoLuuTruFileService.formData.KetThuc | date:'yyyy-MM-dd'"
                    (ngModelChange)="NgayKetThucChange($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SortOrder">Sắp xếp</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input style="text-align: right;" placeholder="0" name="SortOrder"
                    [(ngModel)]="CoSoLuuTruFileService.formData.SortOrder" type="number">
            </div>
        </div>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Code">Tài liệu đính kèm</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input accept="application/pdf, image/png, image/jpeg" type="file" (change)="changeImage($event.target.files)">
            </div>
            <div class="input-field">
                <img class="responsive-img" [src]="CoSoLuuTruFileService.formData.Code">
            </div>
        </div>
    </div>
    <div class="section" *ngIf="ID>0">
        <div class="row">
            <div class="col s12 m12 l12">
                <div class="section section-data-tables">
                    <div class="card">
                        <div class="card-content">
                            <div class="KhungOverflow">
                                <mat-table [dataSource]="PheDuyetDataSource" matSort #PheDuyetSort="matSort">
                                    <ng-container matColumnDef="NguoiYeuCauID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Người yêu cầu
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <div *ngFor="let item of ThanhVienService.list">
                                                <div *ngIf="item.ID==element.NguoiYeuCauID">
                                                    {{item.Email}}
                                                </div>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NgayYeuCau">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày yêu cầu
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            {{element.NgayYeuCau | date:'dd/MM/yyyy h:mm:ss a'}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NoiDungYeuCau">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Yêu cầu
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            {{element.NoiDungYeuCau}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NgayPheDuyet">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày phê duyệt
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            {{element.NgayPheDuyet | date:'dd/MM/yyyy h:mm:ss a'}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Active">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Phê duyệt / Chưa phê duyệt
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <label>
                                                <input type="checkbox" class="filled-in" name="Active{{element.ID}}"
                                                    [(ngModel)]="element.Active" />
                                                <span>.</span>
                                            </label>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NoiDungPheDuyet">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Phê duyệt
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <input placeholder="Nội dung phê duyệt" name="NoiDungPheDuyet{{element.ID}}"
                                                [(ngModel)]="element.NoiDungPheDuyet" type="text">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Save">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <a title="Lưu thay đổi" (click)="PheDuyetSave(element)"
                                                class="btn-floating waves-effect waves-light cyan">
                                                <i class="material-icons">save</i>
                                            </a>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="loading">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Đang tải dữ liệu...
                                        </mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="noData">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Chưa có dữ liệu.
                                        </mat-footer-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="PheDuyetService.displayColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: PheDuyetService.displayColumns;"></mat-row>
                                    <mat-footer-row *matFooterRowDef="['loading']"
                                        [ngClass]="{'hide':PheDuyetDataSource!=null}"></mat-footer-row>
                                    <mat-footer-row *matFooterRowDef="['noData']"
                                        [ngClass]="{'hide':!(PheDuyetDataSource!=null && PheDuyetDataSource.data.length==0)}">
                                    </mat-footer-row>
                                </mat-table>
                                <mat-paginator #PheDuyetPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                    [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>