import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienCoSoLuuTru } from 'src/app/shared/ThanhVienCoSoLuuTru.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienCoSoLuuTruService extends BaseService {
    list: ThanhVienCoSoLuuTru[] | undefined;
    formData!: ThanhVienCoSoLuuTru;
    formDataLogin!: ThanhVienCoSoLuuTru;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVienCoSoLuuTru";
    }
}

