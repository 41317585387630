<div class="row">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">
                        {{DanhMucCoSoLuuTruService.formData.Name}} <span
                            *ngIf="CoSoLuuTruService.list">({{CoSoLuuTruService.list.length }} items)</span></h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="#" style="color: #ffffff;">Đơn vị Tổ chức</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">
                            {{DanhMucCoSoLuuTruService.formData.Name}}
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div class="card-content">
                        <div class="col s12 m12 l3">
                            <mat-select
                                style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                name="DanhMucQuanHuyenID" class="browser-default" [(ngModel)]="quanHuyenID"
                                (selectionChange)="DanhMucXaPhuongGetAllToListAsync()">
                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list" [value]="item.ID">
                                    {{item.Name}}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col s12 m12 l3">
                            <mat-select
                                style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                name="DanhMucXaPhuongID" class="browser-default" [(ngModel)]="xaPhuongID"
                                (selectionChange)="GetByParentID_QuanHuyenID_XaPhuongIDAsync()">
                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list" [value]="item.ID">
                                    {{item.Name}}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col s12 m12 l4">
                            <input matInput [(ngModel)]="searchString" placeholder="Tìm ..."
                                class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                (keyup.enter)="onSearch()">
                        </div>
                        <div class="col s12 m12 l2">
                            <a title="Tìm" (click)="onSearch()"
                                class="btn-floating waves-effect waves-light green darken-1"><i
                                    class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                            <a title="Thêm mới" target="_blank"
                                href="{{URLSub}}/{{DanhMucCoSoLuuTruService.formData.ID}}/0"
                                class="btn-floating waves-effect waves-light green darken-1"><i
                                    class="material-icons">add</i></a>
                        </div>
                        <div class="KhungOverflow">
                            <mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="Code">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Hình đại diện
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <a target="_blank" href="{{element.Code}}" *ngIf="element.Code"
                                            title="{{element.Code}}">
                                            <img class="responsive-img" width="50%" title="{{element.Code}}"
                                                alt="{{element.Code}}" [src]="element.Code"></a>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="TenDangKyKinhDoanh">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element"><a title="{{element.TenDangKyKinhDoanh}}"
                                            target="_blank"
                                            href="{{URLSub}}/{{element.ParentID}}/{{element.ID}}"><b>{{element.TenDangKyKinhDoanh}}</b></a></mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="DienThoai">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{element.DienThoai}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="DiaChi">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{element.DiaChi}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Phê duyệt / Chưa phê duyệt
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <label>
                                            <input type="checkbox" class="filled-in" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active" />
                                            <span>.</span>
                                        </label>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <a style="padding-left: 8px;" (click)="onDelete(element)" *ngIf="element.ID>0"
                                            class="btn-floating waves-effect waves-light red"><i
                                                class="material-icons">delete_outline</i></a>
                                                
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="loading">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Đang tải dữ liệu...
                                    </mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="noData">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Chưa có dữ liệu.
                                    </mat-footer-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="CoSoLuuTruService.displayColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: CoSoLuuTruService.displayColumns;"></mat-row>
                                <mat-footer-row *matFooterRowDef="['loading']"
                                    [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                                <mat-footer-row *matFooterRowDef="['noData']"
                                    [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                                </mat-footer-row>
                            </mat-table>
                            <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>