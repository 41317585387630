<div class="row">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Tổng hợp <span
                            *ngIf="ThanhVienService.list">({{ThanhVienService.list.length}} items)</span>
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="#" style="color: #ffffff;">Thành viên</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Tổng hợp
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div id="1">
                        <div class="card-content">
                            <div class="col s12 m12 l4">
                                <mat-select
                                    style="padding-top:12px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                    name="DanhMucThanhVienID" class="browser-default" [(ngModel)]="danhMucThanhVienID">
                                    <mat-option [value]="0">
                                        Tất cả
                                    </mat-option>
                                    <mat-option *ngFor="let item of DanhMucThanhVienService.list" [value]="item.ID">
                                        {{item.Name}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="col s12 m12 l5">
                                <input matInput [(ngModel)]="searchString" placeholder="Tìm ..."
                                    class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                    (keyup.enter)="onSearch()">
                            </div>
                            <div class="col s12 m12 l3">
                                <a title="Tìm" (click)="onSearch()"
                                    class="btn-floating waves-effect waves-light green darken-1"><i
                                        class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                                <a title="Thêm mới" target="_blank" href="{{URLSub}}/0"
                                    class="btn-floating waves-effect waves-light green darken-1"><i
                                        class="material-icons">add</i></a>
                            </div>
                            <div class="KhungOverflow">
                                <mat-table [dataSource]="dataSource" matSort>
                                    <ng-container matColumnDef="DanhMucThanhVienID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Danh mục
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <div *ngFor="let item of DanhMucThanhVienService.list">
                                                <p *ngIf="element.DanhMucThanhVienID==item.ID">{{item.Name}}</p>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="TaiKhoan">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Tài khoản
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <a title="{{element.TaiKhoan}}" target="_blank"
                                                href="{{URLSub}}/{{element.ID}}"><b>{{element.TaiKhoan}}</b></a>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="HoTen" style="margin-right: 10px;">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Họ tên
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            {{element.HoTen}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="DienThoai" style="margin-right: 10px;">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            {{element.DienThoai}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Active" style="margin-right: 10px;">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Trạng thái
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <label>
                                                <input type="checkbox" class="filled-in" name="Active{{element.ID}}"
                                                    [(ngModel)]="element.Active" />
                                                <span>Phê duyệt / Chưa phê duyệt</span>
                                            </label>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Save">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="loading">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Đang tải dữ liệu...
                                        </mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="noData">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Chưa có dữ liệu.
                                        </mat-footer-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="ThanhVienService.displayColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: ThanhVienService.displayColumns;"></mat-row>
                                    <mat-footer-row *matFooterRowDef="['loading']"
                                        [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                                    <mat-footer-row *matFooterRowDef="['noData']"
                                        [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                                    </mat-footer-row>
                                </mat-table>
                                <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                    [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>