import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HinhAnhTrinhDien } from 'src/app/shared/HinhAnhTrinhDien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class HinhAnhTrinhDienService extends BaseService {
    displayColumns: string[] = ['Code', 'Name', 'Note', 'SortOrder', 'Active', 'Save'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "HinhAnhTrinhDien";
    }
    SaveAndUploadFileAsync(formData: HinhAnhTrinhDien, fileToUpload: FileList) {
        var thanhVienID = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienID) {
            formData.LastUpdatedMembershipID = Number(thanhVienID);
        }
        let url = this.aPIURL + this.controller + '/SaveAndUploadFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(formData));
        if (fileToUpload) {
            if (fileToUpload.length > 0) {
                for (var i = 0; i < fileToUpload.length; i++) {
                    formUpload.append('file[]', fileToUpload[i]);
                }
            }
        }
        return this.httpClient.post(url, formUpload);
    }
}

