import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucDienThoaiLoiTat } from 'src/app/shared/DanhMucDienThoaiLoiTat.model';
import { DanhMucDienThoaiLoiTatService } from 'src/app/shared/DanhMucDienThoaiLoiTat.service';
@Component({
  selector: 'app-danh-muc-dien-thoai-loi-tat',
  templateUrl: './danh-muc-dien-thoai-loi-tat.component.html',
  styleUrls: ['./danh-muc-dien-thoai-loi-tat.component.css']
})
export class DanhMucDienThoaiLoiTatComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  quanHuyenID: number = environment.InitializationNumber;

  constructor(
    public DanhMucDienThoaiLoiTatService: DanhMucDienThoaiLoiTatService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.GetAllAndEmptyToListAsync();
  }



  GetAllAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.DanhMucDienThoaiLoiTatService.GetAllToListAsync().subscribe(
      res => {
        console.log("GetAllAndEmptyToListAsync", res);

        this.DanhMucDienThoaiLoiTatService.list = res as DanhMucDienThoaiLoiTat[];
        this.DanhMucDienThoaiLoiTatService.list= this.DanhMucDienThoaiLoiTatService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.dataSource = new MatTableDataSource(this.DanhMucDienThoaiLoiTatService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    this.fileToUpload = null;
    this.fileToUpload0 = null;
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetAllAndEmptyToListAsync();
    }
  }
  fileToUpload: any;
  fileToUpload0: File = null;
  changeImage(element: DanhMucDienThoaiLoiTat, files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        element.Code = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
  onSave(element: DanhMucDienThoaiLoiTat) {
    this.DanhMucDienThoaiLoiTatService.SaveAndUploadFileAsync(element, this.fileToUpload).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: DanhMucDienThoaiLoiTat) {
    if (confirm(environment.DeleteConfirm)) {
      this.DanhMucDienThoaiLoiTatService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

}
