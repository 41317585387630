import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { CoSoLuuTruComponent } from './co-so-luu-tru/co-so-luu-tru.component';
import { UploadComponent } from './upload/upload.component';
import { DanhMucTinhThanhComponent } from './danh-muc-tinh-thanh/danh-muc-tinh-thanh.component';
import { DanhMucQuanHuyenComponent } from './danh-muc-quan-huyen/danh-muc-quan-huyen.component';
import { DanhMucXaPhuongComponent } from './danh-muc-xa-phuong/danh-muc-xa-phuong.component';
import { DanhMucQuocGiaComponent } from './danh-muc-quoc-gia/danh-muc-quoc-gia.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DanhMucCoSoLuuTruComponent } from './danh-muc-co-so-luu-tru/danh-muc-co-so-luu-tru.component';
import { CoSoLuuTruInfoComponent } from './co-so-luu-tru/co-so-luu-tru-info/co-so-luu-tru-info.component';
import { HoatDongComponent } from './hoat-dong/hoat-dong.component';
import { SuKienComponent } from './su-kien/su-kien.component';
import { TourComponent } from './tour/tour.component';
import { PhongComponent } from './phong/phong.component';
import { DiaDiemComponent } from './dia-diem/dia-diem.component';
import { NhaHangComponent } from './nha-hang/nha-hang.component';
import { MonAnComponent } from './mon-an/mon-an.component';
import { DichVuComponent } from './dich-vu/dich-vu.component';
import { HoTroComponent } from './ho-tro/ho-tro.component';
import { SocialComponent } from './social/social.component';
import { HinhAnhComponent } from './hinh-anh/hinh-anh.component';
import { VideoComponent } from './video/video.component';
import { FileComponent } from './file/file.component';
import { DanhMucThanhVienComponent } from './danh-muc-thanh-vien/danh-muc-thanh-vien.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { ThanhVienInfoComponent } from './thanh-vien/thanh-vien-info/thanh-vien-info.component';
import { DanhMucChucNangComponent } from './danh-muc-chuc-nang/danh-muc-chuc-nang.component';
import { DanhMucUngDungComponent } from './danh-muc-ung-dung/danh-muc-ung-dung.component';
import { ThanhVienPhanQuyenUngDungComponent } from './thanh-vien-phan-quyen-ung-dung/thanh-vien-phan-quyen-ung-dung.component';
import { ThanhVienLichSuTruyCapComponent } from './thanh-vien-lich-su-truy-cap/thanh-vien-lich-su-truy-cap.component';
import { ThanhVienPhanQuyenKhuVucComponent } from './thanh-vien-phan-quyen-khu-vuc/thanh-vien-phan-quyen-khu-vuc.component';
import { ThanhVienPhanQuyenChucNangComponent } from './thanh-vien-phan-quyen-chuc-nang/thanh-vien-phan-quyen-chuc-nang.component';
import { ThanhVienTokenComponent } from './thanh-vien-token/thanh-vien-token.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { ThanhVienQuenMatKhauComponent } from './thanh-vien-quen-mat-khau/thanh-vien-quen-mat-khau.component';
import { CoQuanQuanLyComponent } from './co-quan-quan-ly/co-quan-quan-ly.component';
import { DiaDiemDaChieuComponent } from './dia-diem-da-chieu/dia-diem-da-chieu.component';
import { CoQuanQuanLyInfoComponent } from './co-quan-quan-ly/co-quan-quan-ly-info/co-quan-quan-ly-info.component';
import { ChuongTrinhQuangBaXucTienComponent } from './chuong-trinh-quang-ba-xuc-tien/chuong-trinh-quang-ba-xuc-tien.component';
import { CoSoLuuTruHomeStayComponent } from './co-so-luu-tru/co-so-luu-tru-homestay/co-so-luu-tru-homestay.component';
import { CoSoLuuTruCoSoLuuTruComponent } from './co-so-luu-tru/co-so-luu-tru-co-so-luu-tru/co-so-luu-tru-co-so-luu-tru.component';
import { CoSoLuuTruNhaHangComponent } from './co-so-luu-tru/co-so-luu-tru-nha-hang/co-so-luu-tru-nha-hang.component';
import { CoSoLuuTruKhachSanComponent } from './co-so-luu-tru/co-so-luu-tru-khach-san/co-so-luu-tru-khach-san.component';
import { CoSoLuuTruDonViLuHanhComponent } from './co-so-luu-tru/co-so-luu-tru-don-vi-lu-hanh/co-so-luu-tru-don-vi-lu-hanh.component';
import { CoSoLuuTruDiemDuLichComponent } from './co-so-luu-tru/co-so-luu-tru-diem-du-lich/co-so-luu-tru-diem-du-lich.component';
import { DanhMucPheDuyetComponent } from './danh-muc-phe-duyet/danh-muc-phe-duyet.component';
import { ThanhVienCoQuanQuanLyComponent } from './thanh-vien/thanh-vien-co-quan-quan-ly/thanh-vien-co-quan-quan-ly.component';
import { ThanhVienDuKhachComponent } from './thanh-vien/thanh-vien-du-khach/thanh-vien-du-khach.component';
import { ThanhVienThongBaoComponent } from './thanh-vien-thong-bao/thanh-vien-thong-bao.component';
import { DanhMucVanBanComponent } from './danh-muc-van-ban/danh-muc-van-ban.component';
import { CoQuanQuanLyFileComponent } from './co-quan-quan-ly-file/co-quan-quan-ly-file.component';
import { DatPhongTrucTuyenComponent } from './dat-phong-truc-tuyen/dat-phong-truc-tuyen.component';
import { DanhMucNgonNguComponent } from './danh-muc-ngon-ngu/danh-muc-ngon-ngu.component';
import { HinhAnhTrinhDienComponent } from './hinh-anh-trinh-dien/hinh-anh-trinh-dien.component';
import { Dashboard003Component } from './dashboard003/dashboard003.component';
import { Dashboard002Component } from './dashboard002/dashboard002.component';
import { Dashboard001Component } from './dashboard001/dashboard001.component';
import { DanhMucBaiVietComponent } from './danh-muc-bai-viet/danh-muc-bai-viet.component';
import { CoQuanQuanLyBaiVietComponent } from './co-quan-quan-ly-bai-viet/co-quan-quan-ly-bai-viet.component';
import { DanhMucGiayChungNhanComponent } from './danh-muc-giay-chung-nhan/danh-muc-giay-chung-nhan.component';
import { DanhMucDienThoaiTieuDeComponent } from './danh-muc-dien-thoai-tieu-de/danh-muc-dien-thoai-tieu-de.component';
import { DanhMucHuongDanVienLoaiTheComponent } from './danh-muc-huong-dan-vien-loai-the/danh-muc-huong-dan-vien-loai-the.component';
import { CoSoLuuTruHuongDanVienDuLichComponent } from './co-so-luu-tru-huong-dan-vien-du-lich/co-so-luu-tru-huong-dan-vien-du-lich.component';
import { CoQuanQuanLyChuyenBaiVietComponent } from './co-quan-quan-ly-chuyen-bai-viet/co-quan-quan-ly-chuyen-bai-viet.component';
import { ThanhVienCoSoLuuTruComponent } from './thanh-vien-co-so-luu-tru/thanh-vien-co-so-luu-tru.component';
import { DanhMucDanhHieuComponent } from './danh-muc-danh-hieu/danh-muc-danh-hieu.component';
import { DanhMucDienThoaiLoiTatComponent } from './danh-muc-dien-thoai-loi-tat/danh-muc-dien-thoai-loi-tat.component';



const routes: Routes = [  
  { path: '', redirectTo: '/Dashboard', pathMatch: 'full' },
  {
    path: 'Homepage', component: DashboardComponent,
  },    
  {
    path: 'Dashboard', component: DashboardComponent,
  }, 
  {
    path: 'Dashboard001', component: Dashboard001Component,
  }, 
  {
    path: 'Dashboard002', component: Dashboard002Component,
  }, 
  {
    path: 'Dashboard003', component: Dashboard003Component,
  }, 
  {
    path: 'CoSoLuuTru', component: CoSoLuuTruComponent,
  }, 
  {
    path: 'CoSoLuuTru/CoSoLuuTru', component: CoSoLuuTruCoSoLuuTruComponent,
  }, 
  {
    path: 'CoSoLuuTru/HomeStay', component: CoSoLuuTruHomeStayComponent,
  }, 
  {
    path: 'CoSoLuuTru/DiemDuLich', component: CoSoLuuTruDiemDuLichComponent,
  }, 
  {
    path: 'CoSoLuuTru/NhaHang', component: CoSoLuuTruNhaHangComponent,
  }, 
  {
    path: 'CoSoLuuTru/KhachSan', component: CoSoLuuTruKhachSanComponent,
  }, 
  {
    path: 'CoSoLuuTru/DonViLuHanh', component: CoSoLuuTruDonViLuHanhComponent,
  }, 
  {
    path: 'CoSoLuuTruInfo/:ParentID/:ID', component: CoSoLuuTruInfoComponent,
  },
  {
    path: 'CoQuanQuanLy', component: CoQuanQuanLyComponent,
  },  
  {
    path: 'CoQuanQuanLyInfo/:ID', component: CoQuanQuanLyInfoComponent,
  },
  {
    path: 'CoQuanQuanLyFile', component: CoQuanQuanLyFileComponent,
  }, 
  {
    path: 'DiaDiemDaChieu', component: DiaDiemDaChieuComponent,
  },  
  {
    path: 'DatPhongTrucTuyen', component: DatPhongTrucTuyenComponent,
  }, 
  {
    path: 'HinhAnhTrinhDien', component: HinhAnhTrinhDienComponent,
  }, 
  {
    path: 'CoSoLuuTruHuongDanVienDuLich', component: CoSoLuuTruHuongDanVienDuLichComponent,
  }, 
  {
    path: 'DanhMucHuongDanVienLoaiThe', component: DanhMucHuongDanVienLoaiTheComponent,
  }, 
  {
    path: 'DanhMucDienThoaiTieuDe', component: DanhMucDienThoaiTieuDeComponent,
  }, 
  {
    path: 'DanhMucGiayChungNhan', component: DanhMucGiayChungNhanComponent,
  }, 
  {
    path: 'DanhMucBaiViet', component: DanhMucBaiVietComponent,
  }, 
  {
    path: 'DanhMucNgonNgu', component: DanhMucNgonNguComponent,
  }, 
  {
    path: 'DanhMucVanBan', component: DanhMucVanBanComponent,
  }, 
  {
    path: 'DanhMucPheDuyet', component: DanhMucPheDuyetComponent,
  },  
  {
    path: 'QuangBaXucTien', component: ChuongTrinhQuangBaXucTienComponent,
  },
  {
    path: 'CoQuanQuanLyBaiViet', component: CoQuanQuanLyBaiVietComponent,
  },
  {
    path: 'CoQuanQuanLyChuyenBaiViet', component: CoQuanQuanLyChuyenBaiVietComponent,
  },
  {
    path: 'DanhMucTinhThanh', component: DanhMucTinhThanhComponent,
  },
  {
    path: 'DanhMucQuanHuyen', component: DanhMucQuanHuyenComponent,
  },
  {
    path: 'DanhMucXaPhuong', component: DanhMucXaPhuongComponent,
  },
  {
    path: 'DanhMucQuocGia', component: DanhMucQuocGiaComponent,
  },
  {
    path: 'DanhMucCoSoLuuTru', component: DanhMucCoSoLuuTruComponent,
  },
  {
    path: 'HoatDong', component: HoatDongComponent,
  }, 
  {
    path: 'CoSoLuuTruHoatDong/:ID', component: HoatDongComponent,
  },
  {
    path: 'SuKien', component: SuKienComponent,
  }, 
  {
    path: 'CoSoLuuTruSuKien/:ID', component: SuKienComponent,
  },
  {
    path: 'Tour', component: TourComponent,
  }, 
  {
    path: 'CoSoLuuTruTour/:ID', component: TourComponent,
  },
  {
    path: 'Phong', component: PhongComponent,
  }, 
  {
    path: 'CoSoLuuTruPhong/:ID', component: PhongComponent,
  },
  {
    path: 'DiaDiem', component: DiaDiemComponent,
  }, 
  {
    path: 'NhaHang', component: NhaHangComponent,
  }, 
  {
    path: 'MonAn', component: MonAnComponent,
  }, 
  {
    path: 'CoSoLuuTruMonAn/:ID', component: MonAnComponent,
  },
  {
    path: 'DichVu', component: DichVuComponent,
  }, 
  {
    path: 'CoSoLuuTruDichVu/:ID', component: DichVuComponent,
  },
  {
    path: 'HoTro', component: HoTroComponent,
  }, 
  {
    path: 'Social', component: SocialComponent,
  }, 
  {
    path: 'HinhAnh', component: HinhAnhComponent,
  },
  {
    path: 'Video', component: VideoComponent,
  },
  {
    path: 'File', component: FileComponent,
  }, 
  {
    path: 'DanhMucChucNang', component: DanhMucChucNangComponent,
  },
  {
    path: 'DanhMucUngDung', component: DanhMucUngDungComponent,
  },
  {
    path: 'DanhMucThanhVien', component: DanhMucThanhVienComponent,
  },
  {
    path: 'ThanhVien', component: ThanhVienComponent,
  },
  {
    path: 'ThanhVienInfo/:ID', component: ThanhVienInfoComponent,
  },  
  {
    path: 'ThanhVienCoSoLuuTru', component: ThanhVienCoSoLuuTruComponent,
  },
  {
    path: 'ThanhVienCoQuanQuanLy', component: ThanhVienCoQuanQuanLyComponent,
  },
  {
    path: 'ThanhVienDuKhach', component: ThanhVienDuKhachComponent,
  },
  {
    path: 'ThanhVienPhanQuyenUngDung', component: ThanhVienPhanQuyenUngDungComponent,
  },
  {
    path: 'ThanhVienLichSuTruyCap', component: ThanhVienLichSuTruyCapComponent,
  },
  {
    path: 'ThanhVienPhanQuyenKhuVuc', component: ThanhVienPhanQuyenKhuVucComponent,
  },
  {
    path: 'ThanhVienPhanQuyenChucNang', component: ThanhVienPhanQuyenChucNangComponent,
  },
  {
    path: 'ThanhVienThongBao', component: ThanhVienThongBaoComponent,
  },
  {
    path: 'ThanhVienToken', component: ThanhVienTokenComponent,
  },
  {
    path: 'ThanhVienThongTin', component: ThanhVienThongTinComponent,
  },
  {
    path: 'ThanhVienQuenMatKhau', component: ThanhVienQuenMatKhauComponent,
  },
  {
    path: 'Upload', component: UploadComponent,
  },  
  {
    path: 'DanhMucDanhHieu', component: DanhMucDanhHieuComponent,
  },  
  {
    path: 'DanhMucDienThoaiLoiTat', component: DanhMucDienThoaiLoiTatComponent,
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
