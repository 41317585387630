import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruHuongDanVienDuLich } from 'src/app/shared/CoSoLuuTruHuongDanVienDuLich.model';
import { CoSoLuuTruHuongDanVienDuLichService } from 'src/app/shared/CoSoLuuTruHuongDanVienDuLich.service';
import { DanhMucNgonNgu } from 'src/app/shared/DanhMucNgonNgu.model';
import { DanhMucNgonNguService } from 'src/app/shared/DanhMucNgonNgu.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucHuongDanVienLoaiThe } from 'src/app/shared/DanhMucHuongDanVienLoaiThe.model';
import { DanhMucHuongDanVienLoaiTheService } from 'src/app/shared/DanhMucHuongDanVienLoaiThe.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-co-so-luu-tru-huong-dan-vien-du-lich',
  templateUrl: './co-so-luu-tru-huong-dan-vien-du-lich.component.html',
  styleUrls: ['./co-so-luu-tru-huong-dan-vien-du-lich.component.css']
})
export class CoSoLuuTruHuongDanVienDuLichComponent implements OnInit {

  dataSource: MatTableDataSource<any>
    ;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  constructor(
    public CoSoLuuTruHuongDanVienDuLichService: CoSoLuuTruHuongDanVienDuLichService,
    public DanhMucNgonNguService: DanhMucNgonNguService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucHuongDanVienLoaiTheService: DanhMucHuongDanVienLoaiTheService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.DanhMucNgonNguGetAllToListAsync();
    this.DanhMucTinhThanhGetAllToListAsync();
    this.DanhMucHuongDanVienLoaiTheGetAllToListAsync();
    this.GetAllAndEmptyToListAsync();
  }
  DanhMucNgonNguGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucNgonNguService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucNgonNguService.list = (res as DanhMucNgonNgu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucHuongDanVienLoaiTheGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucHuongDanVienLoaiTheService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucHuongDanVienLoaiTheService.list = (res as DanhMucHuongDanVienLoaiThe[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  GetAllAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruHuongDanVienDuLichService.GetAllAndEmptyToListAsync().subscribe(
      res => {
        this.CoSoLuuTruHuongDanVienDuLichService.list = res as CoSoLuuTruHuongDanVienDuLich[];
        this.dataSource = new MatTableDataSource(this.CoSoLuuTruHuongDanVienDuLichService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    this.fileToUpload = null;
    this.fileToUpload0 = null;
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetAllAndEmptyToListAsync();
    }
  }
  onSave(element: CoSoLuuTruHuongDanVienDuLich) {
    this.CoSoLuuTruHuongDanVienDuLichService.SaveAndUploadFileAsync(element, this.fileToUpload).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: CoSoLuuTruHuongDanVienDuLich) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruHuongDanVienDuLichService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  SoTheNgayHetHanChange(element: CoSoLuuTruHuongDanVienDuLich, value) {
    element.SoTheNgayHetHan = new Date(value);
  }
  fileToUpload: any;
  fileToUpload0: File = null;
  changeImage(element: CoSoLuuTruHuongDanVienDuLich, files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        element.Code = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
}