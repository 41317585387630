import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { DownloadService } from 'src/app/shared/Download.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienLichSuTruyCap } from 'src/app/shared/ThanhVienLichSuTruyCap.model';
import { ThanhVienLichSuTruyCapService } from 'src/app/shared/ThanhVienLichSuTruyCap.service';
import { DanhMucChucNang } from 'src/app/shared/DanhMucChucNang.model';
import { DanhMucChucNangService } from 'src/app/shared/DanhMucChucNang.service';
import { ThanhVienToken } from 'src/app/shared/ThanhVienToken.model';
import { ThanhVienTokenService } from 'src/app/shared/ThanhVienToken.service';
import { ThongBao } from 'src/app/shared/ThongBao.model';
import { ThongBaoService } from 'src/app/shared/ThongBao.service';
import { CoSoLuuTruBinhLuan } from 'src/app/shared/CoSoLuuTruBinhLuan.model';
import { CoSoLuuTruBinhLuanService } from 'src/app/shared/CoSoLuuTruBinhLuan.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CRM';
  domainName = environment.DomainDestination;
  queryString: string = environment.InitializationString;
  queryStringSub: string = environment.InitializationString;
  constructor(
    public router: Router,
    public DownloadService: DownloadService,
    public ThanhVienService: ThanhVienService,
    public ThanhVienLichSuTruyCapService: ThanhVienLichSuTruyCapService,
    public DanhMucChucNangService: DanhMucChucNangService,
    public ThanhVienTokenService: ThanhVienTokenService,
    public ThongBaoService: ThongBaoService,
    public CoSoLuuTruBinhLuanService: CoSoLuuTruBinhLuanService,
  ) {
    this.getByQueryString();
  }
  interval;
  startTimer() {
    this.interval = setInterval(() => {
      this.ThongBaoGetByThanhVienIDToListAsync();
    }, 10000)
  }
  getByQueryString() {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        if (this.queryString.indexOf(environment.AuthenticationToken) > -1) {
          localStorage.setItem(environment.AuthenticationToken, this.queryString.split('=')[this.queryString.split('=').length - 1]);
        }
        this.AuthenticationToken();
      }
    });
    
  }
  AuthenticationToken() {
    let token = localStorage.getItem(environment.AuthenticationToken);
    let isLogin = true;
    if (token == null) {
      isLogin = false;
      this.CheckLogin(isLogin);
    }
    else {
      this.ThanhVienTokenService.AuthenticationByTokenAsync(token).subscribe(
        res => {
          this.ThanhVienTokenService.formData = res as ThanhVienToken;
          if (this.ThanhVienTokenService.formData != null) {
            if (this.ThanhVienTokenService.formData.ParentID > 0) {
              this.ThanhVienService.formDataLogin.ID = this.ThanhVienTokenService.formData.ParentID;
              this.ThanhVienService.GetByIDAsync(this.ThanhVienService.formDataLogin.ID).subscribe(
                res => {
                  this.ThanhVienService.formDataLogin = res as ThanhVien;
                  if (this.ThanhVienService.formDataLogin) {
                    if (this.ThanhVienService.formDataLogin.ParentID == null) {
                      this.ThanhVienService.formDataLogin.ParentID = environment.InitializationNumber;
                    }
                    localStorage.setItem(environment.ThanhVienID, this.ThanhVienService.formDataLogin.ID.toString());
                    localStorage.setItem(environment.ThanhVienParentID, this.ThanhVienService.formDataLogin.ParentID.toString());
                    localStorage.setItem(environment.ThanhVienTaiKhoan, this.ThanhVienService.formDataLogin.TaiKhoan);
                    localStorage.setItem(environment.ThanhVienHoTen, this.ThanhVienService.formDataLogin.HoTen);
                    localStorage.setItem(environment.ThanhVienAvatarURL, this.ThanhVienService.formDataLogin.AvatarURL);
                    this.ThongBaoGetByThanhVienIDToListAsync();
                    this.DanhMucChucNangGetByThanhVienIDToListAsync();
                    this.ThanhVienLichSuTruyCapSaveAsync(this.queryString);
                    this.startTimer();
                  }
                  else {
                    isLogin = false;
                  }
                },
                err => {
                  isLogin = false;
                }
              );
            }
            else {
              isLogin = false;
            }
          }
          else {
            isLogin = false;
          }
          this.CheckLogin(isLogin);
        },
        err => {
          isLogin = false;
          this.CheckLogin(isLogin);
        }
      );
    }
    
  }

  CheckLogin(isLogin : boolean){
    if(!environment.DomainURL.includes("http://localhost")){
      if (isLogin == false) {
        let destinationURL = environment.LoginURL + "?url=" + environment.DomainURL;
        window.location.href = destinationURL;
      }
    }
  }

  DanhMucChucNangGetByThanhVienIDToListAsync() {
    if (this.queryString) {
      if (this.queryString.length > 0) {
        this.queryStringSub = this.queryString.substring(1, this.queryString.length);
      }
    }
    this.DanhMucChucNangService.GetByThanhVienIDToListAsync().subscribe(
      res => {
        this.DanhMucChucNangService.listChild = (res as DanhMucChucNang[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucChucNangService.listParent = [];
        let isLogin = false;
        for (var i = 0; i < this.DanhMucChucNangService.listChild.length; i++) {
          if (this.queryStringSub == this.DanhMucChucNangService.listChild[i].Code) {
            this.DanhMucChucNangService.listChild[i].Active = true;
          }
          else {
            this.DanhMucChucNangService.listChild[i].Active = false;
          }
          if (this.queryStringSub.indexOf(this.DanhMucChucNangService.listChild[i].Code) > -1) {
            isLogin = true;
          }
        }
        for (var i = 0; i < this.DanhMucChucNangService.listChild.length; i++) {
          if (this.DanhMucChucNangService.listChild[i].ParentID == 0) {
            this.DanhMucChucNangService.listChild[i].Active = false;
            this.DanhMucChucNangService.listChild[i].listChild = [];
            for (var j = 0; j < this.DanhMucChucNangService.listChild.length; j++) {
              if (this.DanhMucChucNangService.listChild[i].ID == this.DanhMucChucNangService.listChild[j].ParentID) {
                this.DanhMucChucNangService.listChild[i].listChild.push(this.DanhMucChucNangService.listChild[j]);
                if (this.DanhMucChucNangService.listChild[j].Active == true) {
                  this.DanhMucChucNangService.listChild[i].Active = true;
                }
              }
            }
            this.DanhMucChucNangService.listParent.push(this.DanhMucChucNangService.listChild[i]);
          }
        }

        if (this.queryStringSub.indexOf("ThanhVienThongBao") > -1) {
          isLogin = true;
        }
        if (this.queryStringSub.indexOf("ThanhVienThongTin") > -1) {
          isLogin = true;
        }
        if (this.queryStringSub.indexOf("ThanhVienQuenMatKhau") > -1) {
          isLogin = true;
        }
        if (this.queryStringSub.indexOf("Info") > -1) {
          isLogin = true;
        }
        if (isLogin == false) {
          let destinationURL = environment.DomainDestination + environment.Homepage;
          window.location.href = destinationURL;
        }
      },
      err => {
      }
    );
  }
  ThongBaoGetByThanhVienIDToListAsync() {
    this.ThongBaoService.GetByThanhVienIDToListAsync().subscribe(
      res => {
        this.ThongBaoService.list = (res as ThongBao[]);
        if (this.ThongBaoService.list) {
          this.ThongBaoService.list = this.ThongBaoService.list.sort((a, b) => (a.CreatedDate < b.CreatedDate ? 1 : -1))
        }
      },
      err => {
      }
    );
  }
  ThanhVienLichSuTruyCapSaveAsync(url: string) {
    url = environment.DomainURL + "#" + url;
    this.ThanhVienLichSuTruyCapService.formData.Code = url;
    this.ThanhVienLichSuTruyCapService.formData.Name = this.ThanhVienService.formDataLogin.TaiKhoan;
    this.ThanhVienLichSuTruyCapService.formData.Note = localStorage.getItem(environment.AuthenticationToken);
    this.ThanhVienLichSuTruyCapService.formData.ParentID = this.ThanhVienService.formDataLogin.ID;
    this.ThanhVienLichSuTruyCapService.SaveAsync(this.ThanhVienLichSuTruyCapService.formData).subscribe(
      res => {
      },
      err => {
      }
    );
  }
  onLogout() {
    localStorage.setItem(environment.AuthenticationToken, environment.InitializationString);
    localStorage.setItem(environment.ThanhVienID, environment.InitializationString);
    localStorage.setItem(environment.ThanhVienHoTen, environment.InitializationString);
    localStorage.setItem(environment.ThanhVienTaiKhoan, environment.InitializationString);
    let destinationURL = environment.LoginURL + "?url=" + environment.DomainURL;
    window.location.href = destinationURL;
  }
  onParentLoad(item: DanhMucChucNang) {
    console.log(item);
  }
}
