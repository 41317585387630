import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DownloadService } from 'src/app/shared/Download.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';
import { DanhMucQuocGia } from 'src/app/shared/DanhMucQuocGia.model';
import { DanhMucQuocGiaService } from 'src/app/shared/DanhMucQuocGia.service';
import { Report } from 'src/app/shared/Report.model';
import { ReportService } from 'src/app/shared/Report.service';

@Component({
  selector: 'app-dashboard001',
  templateUrl: './dashboard001.component.html',
  styleUrls: ['./dashboard001.component.css']
})
export class Dashboard001Component implements OnInit {


  isShowLoading: boolean = false;
  dateTimeBegin: Date = new Date();
  dateTimeEnd: Date = new Date();
  constructor(
    public DownloadService: DownloadService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public DanhMucQuocGiaService: DanhMucQuocGiaService,
    public ReportService: ReportService,
  ) { }

  ngOnInit(): void {
   this.onSearch();
  }
  onChangeDateTimeBegin(value) {
    this.dateTimeBegin = new Date(value);
  }
  onChangeDateTimeEnd(value) {
    this.dateTimeEnd = new Date(value);
  }
  onSearch() {
    this.CoSoLuuTruGetAllToListAsync();
    this.DanhMucQuanHuyenGetAllToListAsync();
    this.DanhMucQuocGiaGetAllToListAsync();
  }
  DanhMucQuanHuyenGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync(environment.HoChiMinhCityID).subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));

        this.ReportService.list001 = [];
        for (let i = 0; i < this.DanhMucQuanHuyenService.list.length; i++) {
          this.ReportService.formData = {};
          this.ReportService.formData.Name = this.DanhMucQuanHuyenService.list[i].Name;
          this.ReportService.formData.ThongKe = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe01 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe02 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe03 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe04 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe05 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe06 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe07 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe08 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe09 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe10 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe11 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe12 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.list001.push(this.ReportService.formData);
        }
        this.ReportService.formData = {};
        this.ReportService.formData.Name = "Tổng cộng";
        this.ReportService.formData.ThongKe = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe01 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe02 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe03 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe04 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe05 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe06 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe07 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe08 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe09 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe10 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe11 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe12 = Math.floor(Math.random() * 100000);
        this.ReportService.list001.push(this.ReportService.formData);

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = (res as CoSoLuuTru[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));

        this.ReportService.list002 = [];
        for (let i = 0; i < this.CoSoLuuTruService.list.length; i++) {
          this.ReportService.formData = {};
          this.ReportService.formData.Name = this.CoSoLuuTruService.list[i].TenDangKyKinhDoanh;
          this.ReportService.formData.ThongKe = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe01 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe02 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe03 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe04 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe05 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe06 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe07 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe08 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe09 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe10 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe11 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe12 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.list002.push(this.ReportService.formData);
        }
        this.ReportService.formData = {};
        this.ReportService.formData.Name = "Tổng cộng";
        this.ReportService.formData.ThongKe = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe01 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe02 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe03 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe04 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe05 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe06 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe07 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe08 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe09 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe10 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe11 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe12 = Math.floor(Math.random() * 100000);
        this.ReportService.list002.push(this.ReportService.formData);

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuocGiaGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucQuocGiaService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucQuocGiaService.list = (res as DanhMucQuocGia[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));

        this.ReportService.list003 = [];
        for (let i = 0; i < this.DanhMucQuocGiaService.list.length; i++) {
          this.ReportService.formData = {};
          this.ReportService.formData.Name = this.DanhMucQuocGiaService.list[i].Name;
          this.ReportService.formData.ThongKe = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe01 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe02 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe03 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe04 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe05 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe06 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe07 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe08 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe09 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe10 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe11 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.formData.ThongKe12 = Math.floor(Math.random() * this.DanhMucQuanHuyenService.list[i].SortOrder);
          this.ReportService.list003.push(this.ReportService.formData);
        }
        this.ReportService.formData = {};
        this.ReportService.formData.Name = "Tổng cộng";
        this.ReportService.formData.ThongKe = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe01 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe02 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe03 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe04 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe05 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe06 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe07 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe08 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe09 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe10 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe11 = Math.floor(Math.random() * 100000);
        this.ReportService.formData.ThongKe12 = Math.floor(Math.random() * 100000);
        this.ReportService.list003.push(this.ReportService.formData);

        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onPrint001() {
    this.isShowLoading = true;
    this.DownloadService.Report001ToHTMLAsync(this.ReportService.list001).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    )
  }
  onPrint002() {
    this.isShowLoading = true;
    this.DownloadService.Report001ToHTMLAsync(this.ReportService.list002).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    )
  }
  onPrint003() {
    this.isShowLoading = true;
    this.DownloadService.Report001ToHTMLAsync(this.ReportService.list003).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    )
  }
  onDownloadExcel001() {
    this.isShowLoading = true;
    this.DownloadService.Report001ToExcelAsync(this.ReportService.list001).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {        
        this.isShowLoading = false;
      }
    );
  }
  onDownloadExcel002() {
    this.isShowLoading = true;
    this.DownloadService.Report001ToExcelAsync(this.ReportService.list002).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {        
        this.isShowLoading = false;
      }
    );
  }
  onDownloadExcel003() {
    this.isShowLoading = true;
    this.DownloadService.Report001ToExcelAsync(this.ReportService.list003).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {        
        this.isShowLoading = false;
      }
    );
  }
}
