<div class="row">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Thành viên
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="#" style="color: #ffffff;">Thành viên</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Thành viên
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <div id="basic-tabs" class="card card card-default scrollspy">
                            <div class="card-content">
                                <div class="card-title">
                                    <div class="row">
                                        <div class="col s12 m12 l4">
                                            <h5 class="card-title" style="font-size: 14px;">
                                                <b>{{ThanhVienService.formData.Name}}</b>
                                            </h5>
                                        </div>
                                        <div class="col s12 m12 l8">
                                            <ul class="tabs">
                                                <li class="tab col m3 s12 p-0"><a class="active p-0" href="#1">Thông
                                                        tin</a></li>
                                                <li class="tab col m3 s12 p-0"><a class="p-0" href="#2">Ứng
                                                        dụng và Chức năng</a>
                                                </li>
                                                <li class="tab col m3 s12 p-0"><a class="p-0" href="#3">Khu
                                                        vực</a>
                                                </li>
                                                <li class="tab col m3 s12 p-0"><a class="p-0" href="#4">Lịch sử truy
                                                        cập</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="1">
                                    <div class="row">
                                        <form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
                                            <div class="col s6 m6 l12">
                                                <div class="card-title">
                                                    <a title="Danh sách"
                                                        href="javascript:window.open('','_self').close();"
                                                        style="padding-top: 3px;"
                                                        class="btn-floating waves-effect waves-light green darken-1"><i
                                                            class="material-icons">arrow_back</i></a>&nbsp;&nbsp;&nbsp;
                                                    <button
                                                        [disabled]="!ThanhVienService.formData.TaiKhoan || !ThanhVienService.formData.MatKhau"
                                                        title="Lưu thay đổi" type="submit" style="padding-top: 3px;"
                                                        class="btn-floating waves-effect waves-light cyan"><i
                                                            class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                            <input name="ID" [(ngModel)]="ThanhVienService.formData.ID" type="hidden">                                           
                                            <div class="col s12 m12 l4">
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="TaiKhoan">Tài khoản</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="TaiKhoan"
                                                            [(ngModel)]="ThanhVienService.formData.TaiKhoan" disabled
                                                            placeholder="Tài khoản" type="text">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="MatKhau">Mật khẩu</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="MatKhau"
                                                            [(ngModel)]="ThanhVienService.formData.MatKhau"
                                                            placeholder="Mật khẩu" type="password">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="HoTen">Họ tên</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="HoTen"
                                                            [(ngModel)]="ThanhVienService.formData.HoTen"
                                                            placeholder="Họ tên" type="text">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="DienThoai">Điện thoại</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="DienThoai"
                                                            [(ngModel)]="ThanhVienService.formData.DienThoai"
                                                            placeholder="Điện thoại" type="text">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="Email">Email</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="Email"
                                                            [(ngModel)]="ThanhVienService.formData.Email"
                                                            placeholder="Email" type="email">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="CCCD">CCCD/Hộ chiếu</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="CCCD" [(ngModel)]="ThanhVienService.formData.CCCD"
                                                            placeholder="CCCD/Hộ chiếu" type="text">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="DiaChi">Địa chỉ</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="DiaChi"
                                                            [(ngModel)]="ThanhVienService.formData.DiaChi"
                                                            placeholder="Địa chỉ" type="text">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="Note">Ghi chú</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="Note" [(ngModel)]="ThanhVienService.formData.Note"
                                                            placeholder="Ghi chú" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s12 m12 l4">
                                                <div class="col s6 m6 l4">
                                                    <div class="input-field">
                                                        <label for="ParentID">Đơn vị Tổ chức</label>
                                                    </div>
                                                </div>
                                                <div class="col s6 m6 l8">
                                                    <mat-select
                                                        style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="ParentID" class="browser-default"
                                                        [(ngModel)]="ThanhVienService.formData.ParentID">
                                                        <input (keyup)="onFilterCoSoLuuTru($event.target.value)">
                                                        <mat-option *ngFor="let item of CoSoLuuTruService.listSearch"
                                                            [value]="item.ID">
                                                            {{item.TenDangKyKinhDoanh}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <br />
                                                    <br />
                                                </div>
                                                <div class="col s6 m6 l4">
                                                    <div class="input-field">
                                                        <label for="DanhMucThanhVienID">Danh mục</label>
                                                    </div>
                                                </div>
                                                <div class="col s6 m6 l8">
                                                    <mat-select
                                                        style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucThanhVienID" class="browser-default"
                                                        [(ngModel)]="ThanhVienService.formData.DanhMucThanhVienID">
                                                        <mat-option *ngFor="let item of DanhMucThanhVienService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <br />
                                                    <br />
                                                </div>
                                                <div class="col s6 m6 l4">
                                                    <div class="input-field">
                                                        <label for="DanhMucQuocGiaID">Quốc gia</label>
                                                    </div>
                                                </div>
                                                <div class="col s6 m6 l8">
                                                    <mat-select
                                                        style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucQuocGiaID" class="browser-default"
                                                        [(ngModel)]="ThanhVienService.formData.DanhMucQuocGiaID">
                                                        <mat-option *ngFor="let item of DanhMucQuocGiaService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <br />
                                                    <br />
                                                </div>
                                                <div class="col s6 m6 l4">
                                                    <div class="input-field">
                                                        <label for="DanhMucTinhThanhID">Tỉnh thành</label>
                                                    </div>
                                                </div>
                                                <div class="col s6 m6 l8">
                                                    <mat-select
                                                        style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucTinhThanhID" class="browser-default"
                                                        (selectionChange)="DanhMucQuanHuyenGetToListAsync()"
                                                        [(ngModel)]="ThanhVienService.formData.DanhMucTinhThanhID">
                                                        <mat-option *ngFor="let item of DanhMucTinhThanhService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <br />
                                                    <br />
                                                </div>
                                                <div class="col s6 m6 l4">
                                                    <div class="input-field">
                                                        <label for="DanhMucQuanHuyenID">Quận huyện</label>
                                                    </div>
                                                </div>
                                                <div class="col s6 m6 l8">
                                                    <mat-select
                                                        style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucQuanHuyenID" class="browser-default"
                                                        (selectionChange)="DanhMucXaPhuongGetToListAsync()"
                                                        [(ngModel)]="ThanhVienService.formData.DanhMucQuanHuyenID">
                                                        <mat-option *ngFor="let item of DanhMucQuanHuyenService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <br />
                                                    <br />
                                                </div>
                                                <div class="col s6 m6 l4">
                                                    <div class="input-field">
                                                        <label for="DanhMucXaPhuongID">Xã phường</label>
                                                    </div>
                                                </div>
                                                <div class="col s6 m6 l8">
                                                    <mat-select
                                                        style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucXaPhuongID" class="browser-default"
                                                        [(ngModel)]="ThanhVienService.formData.DanhMucXaPhuongID">
                                                        <mat-option *ngFor="let item of DanhMucXaPhuongService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <br />
                                                    <br />
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="Active">Trạng thái</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <p>
                                                            <label>
                                                                <input type="checkbox" class="filled-in" name="Active"
                                                                    disabled
                                                                    [(ngModel)]="ThanhVienService.formData.Active" />
                                                                <span>Phê duyệt / Chưa phê duyệt</span>
                                                            </label>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s12 m12 l4">
                                                <div class="col s4 m4 l4">
                                                    <div class="input-field">
                                                        <label for="AvatarURL">Hình ảnh</label>
                                                    </div>
                                                </div>
                                                <div class="col s8 m8 l8">
                                                    <div class="input-field">
                                                        <input type="file" (change)="changeImage($event.target.files)">
                                                    </div>
                                                    <div class="input-field">
                                                        <img class="responsive-img"
                                                            [src]="ThanhVienService.formData.AvatarURL">
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div id="2">
                                    <div class="col s12 m12 l6">
                                        <div class="col s6 m6 l6">
                                            <h5><b>Phân quyền Ứng dụng</b></h5>
                                        </div>
                                        <div class="col s6 m6 l6">
                                            <div class="input-field">
                                                <p>
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="ThanhVienPhanQuyenUngDungIsSelectAll"
                                                            [(ngModel)]="ThanhVienPhanQuyenUngDungIsSelectAll"
                                                            (change)="onThanhVienPhanQuyenUngDungIsSelectAllChange()" />
                                                        <span>Chọn hết</span>
                                                    </label>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="KhungOverflow">
                                            <mat-table [dataSource]="dataSourceThanhVienPhanQuyenUngDung" matSort
                                                #sortThanhVienPhanQuyenUngDung="matSort">
                                                <ng-container matColumnDef="DanhMucUngDungID">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Ứng dụng
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        <b>{{element.Name}}
                                                            ({{element.Code}})</b>
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="Active">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Phân quyền
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element">
                                                        <label>
                                                            <input type="checkbox" class="filled-in"
                                                                name="Active{{element.ID}}"
                                                                (change)="onThanhVienPhanQuyenUngDungActiveChange(element,0)"
                                                                [(ngModel)]="element.Active" />
                                                            <span>.</span>
                                                        </label>
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="loading">
                                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                                        Đang tải dữ liệu...
                                                    </mat-footer-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="noData">
                                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                                        Chưa có dữ liệu.
                                                    </mat-footer-cell>
                                                </ng-container>
                                                <mat-header-row
                                                    *matHeaderRowDef="ThanhVienPhanQuyenUngDungService.displayColumns"></mat-header-row>
                                                <mat-row
                                                    *matRowDef="let row; columns: ThanhVienPhanQuyenUngDungService.displayColumns;"></mat-row>
                                                <mat-footer-row *matFooterRowDef="['loading']"
                                                    [ngClass]="{'hide':dataSourceThanhVienPhanQuyenUngDung!=null}"></mat-footer-row>
                                                <mat-footer-row *matFooterRowDef="['noData']"
                                                    [ngClass]="{'hide':!(dataSourceThanhVienPhanQuyenUngDung!=null && dataSourceThanhVienPhanQuyenUngDung.data.length==0)}">
                                                </mat-footer-row>
                                            </mat-table>
                                            <!-- <mat-paginator #paginatorThanhVienPhanQuyenUngDung="matPaginator"
                                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                [showFirstLastButtons]></mat-paginator> -->
                                        </div>
                                    </div>
                                    <div class="col s12 m12 l6">
                                        <div class="col s6 m6 l6">
                                            <h5><b>Phân quyền Chức năng</b></h5>
                                        </div>
                                        <div class="col s6 m6 l6">
                                            <div class="input-field">
                                                <p>
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="ThanhVienPhanQuyenChucNangIsSelectAll"
                                                            [(ngModel)]="ThanhVienPhanQuyenChucNangIsSelectAll"
                                                            (change)="onThanhVienPhanQuyenChucNangIsSelectAllChange()" />
                                                        <span>Chọn hết</span>
                                                    </label>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="KhungOverflow">
                                            <mat-table [dataSource]="dataSourceThanhVienPhanQuyenChucNang" matSort
                                                #sortThanhVienPhanQuyenChucNang="matSort">
                                                <ng-container matColumnDef="DanhMucChucNangID">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Chức năng
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        <b>{{element.Name}}</b>
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="Code">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>URL
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        <b>{{element.Code}}</b>
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="Active">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Phân quyền
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element">
                                                        <label>
                                                            <input type="checkbox" class="filled-in"
                                                                name="Active{{element.ID}}"
                                                                (change)="onThanhVienPhanQuyenChucNangActiveChange(element,0)"
                                                                [(ngModel)]="element.Active" />
                                                            <span>.</span>
                                                        </label>
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="loading">
                                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                                        Đang tải dữ liệu...
                                                    </mat-footer-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="noData">
                                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                                        Chưa có dữ liệu.
                                                    </mat-footer-cell>
                                                </ng-container>
                                                <mat-header-row
                                                    *matHeaderRowDef="ThanhVienPhanQuyenChucNangService.displayColumns"></mat-header-row>
                                                <mat-row
                                                    *matRowDef="let row; columns: ThanhVienPhanQuyenChucNangService.displayColumns;"></mat-row>
                                                <mat-footer-row *matFooterRowDef="['loading']"
                                                    [ngClass]="{'hide':dataSourceThanhVienPhanQuyenChucNang!=null}"></mat-footer-row>
                                                <mat-footer-row *matFooterRowDef="['noData']"
                                                    [ngClass]="{'hide':!(dataSourceThanhVienPhanQuyenChucNang!=null && dataSourceThanhVienPhanQuyenChucNang.data.length==0)}">
                                                </mat-footer-row>
                                            </mat-table>
                                            <!-- <mat-paginator #paginatorThanhVienPhanQuyenChucNang="matPaginator"
                                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                [showFirstLastButtons]></mat-paginator> -->
                                        </div>
                                    </div>
                                </div>
                                <div id="3">
                                    <div class="col s12 m12 l12">
                                        <div class="col s6 m6 l4">
                                            <h5><b>Phân quyền Khu vực</b></h5>
                                        </div>
                                        <div class="col s6 m6 l4">
                                            <mat-select
                                                style="padding-top:12px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                name="danhMucQuanHuyenID" class="browser-default"
                                                [(ngModel)]="danhMucQuanHuyenID"
                                                (selectionChange)="ThanhVienPhanQuyenKhuVucGetToListAsync()">
                                                <mat-option [value]="0">
                                                    Tất cả
                                                </mat-option>
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col s6 m6 l4">
                                            <div class="input-field">
                                                <p>
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="ThanhVienPhanQuyenKhuVucIsSelectAll"
                                                            [(ngModel)]="ThanhVienPhanQuyenKhuVucIsSelectAll"
                                                            (change)="onThanhVienPhanQuyenKhuVucIsSelectAllChange()" />
                                                        <span>Chọn hết</span>
                                                    </label>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="KhungOverflow">
                                            <mat-table [dataSource]="dataSourceThanhVienPhanQuyenKhuVuc" matSort
                                                #sortThanhVienPhanQuyenKhuVuc="matSort">
                                                <ng-container matColumnDef="DanhMucQuanHuyenID">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Quận huyện
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        {{element.Code}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="DanhMucXaPhuongID">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Xã phường
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        {{element.Name}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="Active">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Phân quyền
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element">
                                                        <label>
                                                            <input type="checkbox" class="filled-in"
                                                                name="Active{{element.ID}}"
                                                                (change)="onThanhVienPhanQuyenKhuVucActiveChange(element,0)"
                                                                [(ngModel)]="element.Active" />
                                                            <span>.</span>
                                                        </label>
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="loading">
                                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                                        Đang tải dữ liệu...
                                                    </mat-footer-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="noData">
                                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                                        Chưa có dữ liệu.
                                                    </mat-footer-cell>
                                                </ng-container>
                                                <mat-header-row
                                                    *matHeaderRowDef="ThanhVienPhanQuyenKhuVucService.displayColumns"></mat-header-row>
                                                <mat-row
                                                    *matRowDef="let row; columns: ThanhVienPhanQuyenKhuVucService.displayColumns;"></mat-row>
                                                <mat-footer-row *matFooterRowDef="['loading']"
                                                    [ngClass]="{'hide':dataSourceThanhVienPhanQuyenKhuVuc!=null}"></mat-footer-row>
                                                <mat-footer-row *matFooterRowDef="['noData']"
                                                    [ngClass]="{'hide':!(dataSourceThanhVienPhanQuyenKhuVuc!=null && dataSourceThanhVienPhanQuyenKhuVuc.data.length==0)}">
                                                </mat-footer-row>
                                            </mat-table>
                                            <!-- <mat-paginator #paginatorThanhVienPhanQuyenKhuVuc="matPaginator"
                                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                [showFirstLastButtons]></mat-paginator> -->
                                        </div>
                                    </div>
                                </div>
                                <div id="4">
                                    <div class="col s12 m12 l6">
                                        <div class="col s6 m6 l6">
                                            <h5><b>Token</b></h5>
                                        </div>
                                        <div class="col s12 m12 l4">
                                            <input matInput [(ngModel)]="searchStringThanhVienToken"
                                                placeholder="Tìm ..."
                                                class="mat-input-element mat-form-field-autofill-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col s12 m12 l2">
                                            <a title="Tìm" (click)="ThanhVienTokenSearch()"
                                                class="btn-floating waves-effect waves-light green darken-1"><i
                                                    class="material-icons">search</i></a>
                                        </div>
                                        <div class="KhungOverflow">
                                            <mat-table [dataSource]="dataSourceThanhVienToken" matSort
                                                #sortThanhVienToken="matSort">
                                                <ng-container matColumnDef="Token">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Token
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        {{element.Token}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="NgayBatDau">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Bắt đầu
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        {{element.NgayBatDau | date:'dd-MM-yyyy'}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="NgayKetThuc">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Kết thúc
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        {{element.NgayKetThuc | date:'dd-MM-yyyy'}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="Active">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Hiệu lực
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element">
                                                        <label>
                                                            <input type="checkbox" class="filled-in"
                                                                name="Active{{element.ID}}"
                                                                [(ngModel)]="element.Active" />
                                                            <span>.</span>
                                                        </label>
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="loading">
                                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                                        Đang tải dữ liệu...
                                                    </mat-footer-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="noData">
                                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                                        Chưa có dữ liệu.
                                                    </mat-footer-cell>
                                                </ng-container>
                                                <mat-header-row
                                                    *matHeaderRowDef="ThanhVienTokenService.displayColumns"></mat-header-row>
                                                <mat-row
                                                    *matRowDef="let row; columns: ThanhVienTokenService.displayColumns;"></mat-row>
                                                <mat-footer-row *matFooterRowDef="['loading']"
                                                    [ngClass]="{'hide':dataSourceThanhVienToken!=null}"></mat-footer-row>
                                                <mat-footer-row *matFooterRowDef="['noData']"
                                                    [ngClass]="{'hide':!(dataSourceThanhVienToken!=null && dataSourceThanhVienToken.data.length==0)}">
                                                </mat-footer-row>
                                            </mat-table>
                                            <mat-paginator #paginatorThanhVienToken="matPaginator"
                                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                [showFirstLastButtons]></mat-paginator>
                                        </div>
                                    </div>
                                    <div class="col s12 m12 l6">
                                        <div class="col s6 m6 l6">
                                            <h5><b>Lịch sử truy cập</b></h5>
                                        </div>
                                        <div class="col s12 m12 l4">
                                            <input matInput [(ngModel)]="searchStringThanhVienLichSuTruyCap"
                                                placeholder="Tìm ..."
                                                class="mat-input-element mat-form-field-autofill-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col s12 m12 l2">
                                            <a title="Tìm" (click)="ThanhVienLichSuTruyCapSearch()"
                                                class="btn-floating waves-effect waves-light green darken-1"><i
                                                    class="material-icons">search</i></a>
                                        </div>
                                        <div class="KhungOverflow">
                                            <mat-table [dataSource]="dataSourceThanhVienLichSuTruyCap" matSort
                                                #sortThanhVienLichSuTruyCap="matSort">
                                                <ng-container matColumnDef="Note">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Token
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        {{element.Note}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="CreatedDate">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày truy cập
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        {{element.CreatedDate}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="Code">
                                                    <mat-header-cell *matHeaderCellDef mat-sort-header>URL
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                        {{element.Code}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="loading">
                                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                                        Đang tải dữ liệu...
                                                    </mat-footer-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="noData">
                                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                                        Chưa có dữ liệu.
                                                    </mat-footer-cell>
                                                </ng-container>
                                                <mat-header-row
                                                    *matHeaderRowDef="ThanhVienLichSuTruyCapService.displayColumns"></mat-header-row>
                                                <mat-row
                                                    *matRowDef="let row; columns: ThanhVienLichSuTruyCapService.displayColumns;"></mat-row>
                                                <mat-footer-row *matFooterRowDef="['loading']"
                                                    [ngClass]="{'hide':dataSourceThanhVienLichSuTruyCap!=null}"></mat-footer-row>
                                                <mat-footer-row *matFooterRowDef="['noData']"
                                                    [ngClass]="{'hide':!(dataSourceThanhVienLichSuTruyCap!=null && dataSourceThanhVienLichSuTruyCap.data.length==0)}">
                                                </mat-footer-row>
                                            </mat-table>
                                            <mat-paginator #paginatorThanhVienLichSuTruyCap="matPaginator"
                                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                [showFirstLastButtons]></mat-paginator>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<br />
<app-loading *ngIf="isShowLoading"></app-loading>