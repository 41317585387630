import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { HinhAnhTrinhDien } from 'src/app/shared/HinhAnhTrinhDien.model';
import { HinhAnhTrinhDienService } from 'src/app/shared/HinhAnhTrinhDien.service';

import { HinhAnhTrinhDienDetailComponent } from './hinh-anh-trinh-dien-detail/hinh-anh-trinh-dien-detail.component';

@Component({
  selector: 'app-hinh-anh-trinh-dien',
  templateUrl: './hinh-anh-trinh-dien.component.html',
  styleUrls: ['./hinh-anh-trinh-dien.component.css']
})
export class HinhAnhTrinhDienComponent implements OnInit {

  dataSource: MatTableDataSource<any>
    ;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;

  constructor(
    public HinhAnhTrinhDienService: HinhAnhTrinhDienService,    
    public NotificationService: NotificationService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void { 
    this.onSearch();
  }  

  GetToListAsync() {
    this.isShowLoading = true;
    this.HinhAnhTrinhDienService.GetAllToListAsync().subscribe(
      res => {
        this.HinhAnhTrinhDienService.list = (res as HinhAnhTrinhDien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.dataSource = new MatTableDataSource(this.HinhAnhTrinhDienService.list);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToListAsync();
    }
  }
  onDelete(element: HinhAnhTrinhDien) {
    if (confirm(environment.DeleteConfirm)) {
      this.HinhAnhTrinhDienService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onAdd(ID: any) {
    this.HinhAnhTrinhDienService.GetByIDAsync(ID).subscribe(
      res => {
        this.HinhAnhTrinhDienService.formData = res as HinhAnhTrinhDien;     
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(HinhAnhTrinhDienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.GetToListAsync();
        });
      },
      err => {
      }
    );
  }
}
