import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, Chart, ChartConfiguration, ChartData } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { environment } from 'src/environments/environment';
import { DownloadService } from 'src/app/shared/Download.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucCoSoLuuTru } from 'src/app/shared/DanhMucCoSoLuuTru.model';
import { DanhMucCoSoLuuTruService } from 'src/app/shared/DanhMucCoSoLuuTru.service';

@Component({
  selector: 'app-dashboard002',
  templateUrl: './dashboard002.component.html',
  styleUrls: ['./dashboard002.component.css']
})
export class Dashboard002Component implements OnInit {

  isShowLoading: boolean = false;
  year: number = new Date().getFullYear();
  duKhach: number = Math.floor(Math.random() * this.year * 1000);
  donViDuLich: number = Math.floor(Math.random() * this.year * 100);
  tourDuLich: number = Math.floor(Math.random() * this.year * 10);
  hoatDongDuLich: number = Math.floor(Math.random() * this.year * 10);
  constructor(
    public DownloadService: DownloadService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucCoSoLuuTruService: DanhMucCoSoLuuTruService,
  ) {
  }

  ngOnInit(): void {
    this.Report001Async();
  }
  public ChartOptionsReport001: ChartOptions = {
    animation: {
    },
    scales: {
      xAxes: [{
        ticks: {
        }
      }],
      yAxes: [
        {
          id: 'Right',
          scaleLabel: {
            display: true,
            labelString: 'Line',            
          },
          type: 'linear',
          position: 'right',
          ticks: {
            callback: function (value, index, values) {
              return value;
            }
          }
        },
        {
          id: 'Left',
          scaleLabel: {
            display: true,
            labelString: 'Bar',            
          },
          type: 'linear',
          position: 'left',
          ticks: {
            callback: function (value, index, values) {
              return value;
            }
          }
        }
      ]
    },
    responsive: true,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    }
  };
  public ChartColorsReport001: Color[] = [   
  ]
  public ChartLabelsReport001: Label[] = [];
  public ChartTypeReport001: ChartType = 'line';
  public ChartLegendReport001 = true;
  public ChartPluginsReport001 = [];

  public ChartDataReport001: ChartDataSets[] = [
  ];
  Report001Async() {
    this.isShowLoading = true;
    let Thang = [];
    let ThongKe001 = [];
    let ThongKe0011 = [];
    let ThongKe002 = [];
    let ThongKe003 = [];
    for (let i = 1; i < 13; i++) {
      Thang.push(i);
      ThongKe001.push(Math.floor(Math.random() * i * 5));
      ThongKe0011.push(Math.floor(Math.random() * i * 6));
      ThongKe002.push(Math.floor(Math.random() * i));
      ThongKe003.push(Math.floor(Math.random() * i * 2));
    }
    let label001: string = 'Bar001';
    let label0011: string = 'Bar0011';
    let label002: string = 'Line002';
    let label003: string = 'Line003';
    this.ChartLabelsReport001 = Thang;
    this.ChartDataReport001 = [      
      { data: ThongKe002, label: label002, stack: 'a', type: 'line', fill: false, yAxisID: "Right", },
      { data: ThongKe003, label: label003, stack: 'a1', type: 'line', fill: false, yAxisID: "Right", },
      { data: ThongKe001, label: label001, stack: 'b', type: 'bar', yAxisID: "Left", backgroundColor: '#33FFBC'},
      { data: ThongKe0011, label: label0011, stack: 'b1', type: 'bar', yAxisID: "Left", backgroundColor: '#8BFF33' },
    ];
  }
}
