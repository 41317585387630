import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { HinhAnhTrinhDien } from 'src/app/shared/HinhAnhTrinhDien.model';
import { HinhAnhTrinhDienService } from 'src/app/shared/HinhAnhTrinhDien.service';

@Component({
  selector: 'app-hinh-anh-trinh-dien-detail',
  templateUrl: './hinh-anh-trinh-dien-detail.component.html',
  styleUrls: ['./hinh-anh-trinh-dien-detail.component.css']
})
export class HinhAnhTrinhDienDetailComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;


  constructor(
    public HinhAnhTrinhDienService: HinhAnhTrinhDienService,
    public NotificationService: NotificationService,
    public dialogRef: MatDialogRef<HinhAnhTrinhDienDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ID = data["ID"] as number;
  }
  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }
  onSubmit(form: NgForm) {
    this.isShowLoading = true;
    this.HinhAnhTrinhDienService.SaveAndUploadFileAsync(this.HinhAnhTrinhDienService.formData, this.fileToUpload).subscribe(
      res => {
        this.HinhAnhTrinhDienService.formData = res as HinhAnhTrinhDien;
        this.onClose();
        this.NotificationService.success(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  fileToUpload: any;
  fileToUpload0: File = null;
  changeImage(files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.HinhAnhTrinhDienService.formData.Code = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
}