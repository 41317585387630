import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { CoQuanQuanLyFile } from 'src/app/shared/CoQuanQuanLyFile.model';
import { CoQuanQuanLyFileService } from 'src/app/shared/CoQuanQuanLyFile.service';
import { CoQuanQuanLy } from 'src/app/shared/CoQuanQuanLy.model';
import { CoQuanQuanLyService } from 'src/app/shared/CoQuanQuanLy.service';
import { DanhMucVanBan } from 'src/app/shared/DanhMucVanBan.model';
import { DanhMucVanBanService } from 'src/app/shared/DanhMucVanBan.service';

@Component({
  selector: 'app-co-quan-quan-ly-file-detail',
  templateUrl: './co-quan-quan-ly-file-detail.component.html',
  styleUrls: ['./co-quan-quan-ly-file-detail.component.css']
})
export class CoQuanQuanLyFileDetailComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;


  constructor(
    public CoQuanQuanLyFileService: CoQuanQuanLyFileService,
    public CoQuanQuanLyService: CoQuanQuanLyService,
    public DanhMucVanBanService: DanhMucVanBanService,
    public NotificationService: NotificationService,
    public dialogRef: MatDialogRef<CoQuanQuanLyFileDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ID = data["ID"] as number;
  }
  ngOnInit(): void {
    this.CoQuanQuanLyGetAllToListAsync();
    this.DanhMucVanBanGetAllToListAsync();
  }
  CoQuanQuanLyGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoQuanQuanLyService.GetAllToListAsync().subscribe(
      res => {
        this.CoQuanQuanLyService.list = (res as CoQuanQuanLy[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucVanBanGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucVanBanService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucVanBanService.list = (res as DanhMucVanBan[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onClose() {
    this.dialogRef.close();
  }
  onSubmit(form: NgForm) {    
    this.CoQuanQuanLyFileService.SaveAndUploadFileAsync( this.CoQuanQuanLyFileService.formData, this.fileToUpload).subscribe(
      res => {
        this.CoQuanQuanLyFileService.formData = res as CoQuanQuanLyFile;
        this.onClose();
        this.NotificationService.success(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  fileToUpload: any;
  fileToUpload0: File = null;
  changeFile(files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.CoQuanQuanLyFileService.formData.Code = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
}