<div class="row" style="padding-bottom: 50px;">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Upload</h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="#" style="color: #ffffff;">Upload</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Danh mục
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
   
    <div class="row">
        <div class="col s6">
            <div class="container">
                <div class="section section-data-tables">
                    <div class="card">
                        <div class="card-content">
                            <div class="col s12 m12 l12">
                                <h5>Upload bài viết</h5>
                                <div class="input-field col s12">
                                    Bước 01: <a target="_blank" href="{{excelCoSoLuuTruURL}}">Tải về</a> file
                                    excel mẫu
                                </div>
                                <div class="input-field col s12">
                                    Bước 02: Chọn file excel có dữ liệu:
                                    <input type="file" #uploadCoQuanQuanLyBaiViet (change)="changeCoQuanQuanLyBaiViet($event.target.files)">
                                </div>
                                <div class="input-field col s12">
                                    Bước 03: Chọn danh mục bài viết:
                                    <mat-select
                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                        name="ParentID" class="browser-default" [(ngModel)]="this.danhMucBaiVietID"
                                        (selectionChange)="changeSelectDanhMucBaiViet()">
                                        <mat-option *ngFor="let item of DanhMucBaiVietService.list" [value]="item.ID">
                                            {{item.Name}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <div class="input-field col s12">
                                    <button [disabled]="!isCoQuanQuanLyBaiViet" class="btn btn-primary"
                                    (click)="onSubmitCoQuanQuanLyBaiViet()">Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col s6">
            <div class="container">
                <div class="section section-data-tables">
                    <div class="card">
                        <div class="card-content">
                            <div class="col s12 m12 l12">
                                <h5>Upload Đơn vị Tổ chức</h5>
                                <div class="input-field col s12">
                                    Bước 01: <a target="_blank" href="{{excelCoSoLuuTruURL}}">Tải về</a> file
                                    excel mẫu
                                </div>
                                <div class="input-field col s12">
                                    Bước 02: Chọn file excel có dữ liệu:
                                    <input type="file" #uploadCoSoLuuTru (change)="changeCoSoLuuTru($event.target.files)">
                                </div>
                                <div class="input-field col s12">
                                    Bước 03: Chọn Đơn vị Tổ chức:
                                    <mat-select
                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                        name="ParentID" class="browser-default" [(ngModel)]="this.danhMucCoSoLuuTruID"
                                        (selectionChange)="changeSelectDanhMucCoSoLuuTru()">
                                        <mat-option *ngFor="let item of DanhMucCoSoLuuTruService.list" [value]="item.ID">
                                            {{item.Name}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <div class="input-field col s12">
                                    <button [disabled]="!isCoSoLuuTru" class="btn btn-primary"
                                        (click)="onSubmitCoSoLuuTru()">Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--
    <div class="row">
        <div class="col s6">
            <div class="container">
                <div class="section section-data-tables">
                    <div class="card">
                        <div class="card-content">
                            <div class="col s12 m12 l12">
                                <h5>Upload nhà hàng</h5>
                                <div class="input-field col s12">
                                    Bước 01: <a target="_blank" href="{{excelCoSoLuuTru_NhaHangURL}}">Tải về</a> file
                                    excel mẫu
                                </div>
                                <div class="input-field col s12">
                                    Bước 02: Chọn file excel có dữ liệu:
                                    <input type="file" #uploadCoSoLuuTru_NhaHang (change)="changeCoSoLuuTru_NhaHang($event.target.files)">
                                </div>
                                <div class="input-field col s12">
                                    <button [disabled]="!isCoSoLuuTru_NhaHang" class="btn btn-primary"
                                        (click)="onSubmitCoSoLuuTru_NhaHang()">Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col s6">
            <div class="container">
                <div class="section section-data-tables">
                    <div class="card">
                        <div class="card-content">
                            <div class="col s12 m12 l12">
                                <h5>Upload đơn vị lữ hành</h5>
                                <div class="input-field col s12">
                                    Bước 01: <a target="_blank" href="{{excelCoSoLuuTru_DonViLuHanhURL}}">Tải về</a> file
                                    excel mẫu
                                </div>
                                <div class="input-field col s12">
                                    Bước 02: Chọn file excel có dữ liệu:
                                    <input type="file" #uploadCoSoLuuTru_DonViLuHanh (change)="changeCoSoLuuTru_DonViLuHanh($event.target.files)">
                                </div>
                                <div class="input-field col s12">
                                    <button [disabled]="!isCoSoLuuTru_DonViLuHanh" class="btn btn-primary"
                                        (click)="onSubmitCoSoLuuTru_DonViLuHanh()">Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col s6">
            <div class="container">
                <div class="section section-data-tables">
                    <div class="card">
                        <div class="card-content">
                            <div class="col s12 m12 l12">
                                <h5>Upload điểm du lịch</h5>
                                <div class="input-field col s12">
                                    Bước 01: <a target="_blank" href="{{excelCoSoLuuTru_DiemDuLichURL}}">Tải về</a> file
                                    excel mẫu
                                </div>
                                <div class="input-field col s12">
                                    Bước 02: Chọn file excel có dữ liệu:
                                    <input type="file" #uploadCoSoLuuTru_DiemDuLich (change)="changeCoSoLuuTru_DiemDuLich($event.target.files)">
                                </div>
                                <div class="input-field col s12">
                                    <button [disabled]="!isCoSoLuuTru_DiemDuLich" class="btn btn-primary"
                                        (click)="onSubmitCoSoLuuTru_DiemDuLich()">Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="col s6">
            <div class="container">
                <div class="section section-data-tables">
                    <div class="card">
                        <div class="card-content">
                            <div class="col s12 m12 l12">
                                <h5>Upload khách sạn</h5>
                                <div class="input-field col s12">
                                    Bước 01: <a target="_blank" href="{{excelCoSoLuuTru_KhachSanURL}}">Tải về</a> file
                                    excel mẫu
                                </div>
                                <div class="input-field col s12">
                                    Bước 02: Chọn file excel có dữ liệu:
                                    <input type="file" #uploadCoSoLuuTru_KhachSan (change)="changeCoSoLuuTru_KhachSan($event.target.files)">
                                </div>
                                <div class="input-field col s12">
                                    <button [disabled]="!isCoSoLuuTru_KhachSan" class="btn btn-primary"
                                        (click)="onSubmitCoSoLuuTru_KhachSan()">Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    -->

    <div class="row">
        
        <!--
        <div class="col s6">
            <div class="container">
                <div class="section section-data-tables">
                    <div class="card">
                        <div class="card-content">
                            <div class="col s12 m12 l12">
                                <h5>Upload Quốc Gia</h5>
                                <div class="input-field col s12">
                                    Bước 01: <a target="_blank" href="{{excelDanhMucQuocGiaURL}}">Tải về</a> file
                                    excel mẫu
                                </div>
                                <div class="input-field col s12">
                                    Bước 02: Chọn file excel có dữ liệu:
                                    <input type="file" #uploadDanhMucQuocGia
                                        (change)="changeDanhMucQuocGia($event.target.files)">
                                </div>
                                <div class="input-field col s12">
                                    <button [disabled]="!isDanhMucQuocGia" class="btn btn-primary"
                                        (click)="onSubmitDanhMucQuocGia()">Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        -->
    </div>    


    
</div>
<app-loading *ngIf="isShowLoading"></app-loading>