<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Hình ảnh trình diễn</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <input name="ID" [(ngModel)]="HinhAnhTrinhDienService.formData.ID" type="hidden">
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Tiêu đề</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Tiêu đề" name="Name" [(ngModel)]="HinhAnhTrinhDienService.formData.Name"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">URL</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="URL" name="Note" [(ngModel)]="HinhAnhTrinhDienService.formData.Note" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SortOrder">Sắp xếp</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input style="text-align: right;" placeholder="0" name="SortOrder" [(ngModel)]="HinhAnhTrinhDienService.formData.SortOrder" type="number">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Active">Tình trạng</label>
            </div>
        </div>
        <div class="col s8 m8 l8 mt-2 mb-3">
            <div class="input-field">
                <p>
                    <label>
                        <input type="checkbox" class="filled-in" name="Active"
                            [(ngModel)]="HinhAnhTrinhDienService.formData.Active" />
                        <span>Phê duyệt / Chưa phê duyệt</span>
                    </label>
                </p>
            </div>
        </div>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Code">Hình ảnh</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input type="file" (change)="changeImage($event.target.files)">
            </div>
            <div class="input-field">
                <img class="responsive-img" [src]="HinhAnhTrinhDienService.formData.Code">
            </div>
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>