import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienCoSoLuuTru } from 'src/app/shared/ThanhVienCoSoLuuTru.model';
import { ThanhVienCoSoLuuTruService } from 'src/app/shared/ThanhVienCoSoLuuTru.service';


@Component({
  selector: 'app-thanh-vien-co-so-luu-tru',
  templateUrl: './thanh-vien-co-so-luu-tru.component.html',
  styleUrls: ['./thanh-vien-co-so-luu-tru.component.css']
})
export class ThanhVienCoSoLuuTruComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  thanhVienID: number = environment.InitializationNumber;
  URLSub: string = environment.DomainDestination + "CoSoLuuTruInfo";

  constructor(
    public router: Router,
    public CoSoLuuTruService: CoSoLuuTruService,
    public ThanhVienService: ThanhVienService,
    public ThanhVienCoSoLuuTruService: ThanhVienCoSoLuuTruService,
    public NotificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.ThanhVienGetAllToListAsync();
    this.ThanhVienCoSoLuuTruGetAllToListAsync();
  }


  ThanhVienGetAllToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.ThanhVienService.list = (res as ThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        if (this.ThanhVienService.list) {
          if (this.ThanhVienService.list.length > 0) {
            this.thanhVienID = this.ThanhVienService.list[0].ID;
            this.GetByThanhVienIDToListAsync();
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienCoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienCoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.ThanhVienCoSoLuuTruService.list = (res as ThanhVienCoSoLuuTru[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetByThanhVienIDToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetByThanhVienIDToListAsync(this.thanhVienID).subscribe(res => {
      this.CoSoLuuTruService.list = (res as CoSoLuuTru[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
      for (let i = 0; i < this.CoSoLuuTruService.list.length; i++) {
        for (let j = 0; j < this.ThanhVienCoSoLuuTruService.list.length; j++) {
          if (this.CoSoLuuTruService.list[i].ID == this.ThanhVienCoSoLuuTruService.list[j].CoSoLuuTruID) {
            this.CoSoLuuTruService.list[i].LastUpdatedMembershipID = this.ThanhVienCoSoLuuTruService.list[j].ParentID;
            j = this.ThanhVienCoSoLuuTruService.list.length;
          }
        }
      }
      this.dataSource = new MatTableDataSource(this.CoSoLuuTruService.list);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isShowLoading = false;
    },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  GetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(res => {
      this.CoSoLuuTruService.list = (res as CoSoLuuTru[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
      for (let i = 0; i < this.CoSoLuuTruService.list.length; i++) {
        for (let j = 0; j < this.ThanhVienCoSoLuuTruService.list.length; j++) {
          if (this.CoSoLuuTruService.list[i].ID == this.ThanhVienCoSoLuuTruService.list[j].CoSoLuuTruID) {
            this.CoSoLuuTruService.list[i].LastUpdatedMembershipID = this.ThanhVienCoSoLuuTruService.list[j].ParentID;
            j = this.ThanhVienCoSoLuuTruService.list.length;
          }
        }
      }
      this.dataSource = new MatTableDataSource(this.CoSoLuuTruService.list);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.filter = this.searchString.toLowerCase();
      this.isShowLoading = false;
    },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  onSearch() {
    if (this.searchString.length > 0) {
      this.GetAllToListAsync();
    }
    else {
      this.GetByThanhVienIDToListAsync();
    }
  }
  onDelete(element: CoSoLuuTru) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
}